<template>
  <div>
    <div class="container min-vh-100 d-flex justify-content-center align-items-center">
      <div class="login-wrap">
        <div class="row">
          <div class="col-lg-6 bg-brand d-flex align-items-center justify-content-center">
            <div class="text-center">
              <img src="/images/MVS-logo-white.svg" width="220" alt="Logo" class="mb-2">
              <p class=" text-uppercase font-14 text-white" style="letter-spacing: 5px;">admin</p>
            </div>
          </div>
          <div class="col-lg-6 bg-white py-5" v-if="emailProcess">
            <div class="px-5">
              <h4 class="font-medium text-brand mb-0 text-center">Welcome to MyVitalScan admin! </h4>
              <p class="text-brand text-center mb-4">Please enter you email address</p>
              <Form @submit="onSendOtp('SEND')" ref="loginForm">
                <div class="form-floating mb-3">
                  <Field type="email" class="form-control" name="email" v-model="email" rules="required:email|email"
                    :validateOnInput="true" id="email" placeholder="name@example.com" />
                  <label for="email">Email address <span class="text-danger">*</span></label>
                  <ErrorMessage name="email" class="validation-msg" />
                </div>
                <button type="submit" id="save-btn" class="btn btn-login mb-3">Send OTP</button>
              </Form>
            </div>
          </div>
          <div class="col-lg-6 bg-white py-5" v-if="otpProcess">
            <div class="px-5">
              <h4 class="font-medium text-brand mb-0 text-center">Welcome to MyVitalScan admin! </h4>
              <p class="text-brand text-center mb-4">Your OTP for forgot password verification has been sent to your email
                address</p>
              <Form @submit="validateOtp()" ref="loginForm">
                <div class="d-flex justify-content-center">
                  <vue3-otp-input ref="otpInput" input-classes="otp-input"
                    :conditionalClass="['one', 'two', 'three', 'four','five','six']" separator="-" inputType="letter-numeric"
                    :num-inputs="6" v-model:value="bindValue" :should-auto-focus="true" @on-change="handleOnChange"
                    @on-complete="handleOnComplete" :placeholder="['*', '*', '*', '*','*','*']" />
                </div>
                <p class="text-center mt-3 mb-4" id="counterTime"></p>
                <p style="cursor: pointer" v-show="showResendOtp == true" class="text-center btn mt-3 mb-4"
                  @click="onReSendOtp()" id="resendOtpDisplay">
                  Resend OTP
                </p>
                <button type="submit" id="verify-otp" class="btn btn-login mb-3">Verify</button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
}

.otp-input.is-complete {
  background-color: #e4e4e4;
}

.otp-input.error {
  border: 1px solid red !important;
}

.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
<script>
import Vue3OtpInput from "vue3-otp-input";
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
  name: "ForgetPassword",
  components: {
    Form,
    Field,
    ErrorMessage,
    Vue3OtpInput
  },
  data() {
    return {
      email: "",
      emailProcess: true,
      otpProcess: false,
      otp: "",
      passwordFieldType: "password",
      image: "/images/password.svg",
      has_mail_sended: false,
      showResendOtp: false,
      bindValue: "",
    };
  },
  mounted() {

  },
  methods: {
    onClearForm(formRefName) {
      this.$refs[formRefName].resetForm();
    },

    onSwitchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      this.image =
        this.image === "/images/password.svg" ? "/images/password.svg" : "/images/password.svg";
    },

    setCounter() {
      var counter = 59;
      var interval = setInterval(function () {
        counter--;
        if (counter < 0) {
          var counterDisplay = window.$("#counterTime");
          var resendOtpDisplay = window.$("#resendOtpDisplay");
          if (counterDisplay.hasClass("d-block")) {
            counterDisplay.removeClass("d-block");
            counterDisplay.addClass("d-none");
          } else {
            counterDisplay.addClass("d-none");
          }
          if (resendOtpDisplay.hasClass("d-none")) {
            resendOtpDisplay.removeClass("d-none");
            resendOtpDisplay.addClass("d-block");
          } else {
            resendOtpDisplay.addClass("d-block");
          }
          this.showResendOtp = true;
          clearInterval(interval);
          return;
        } else {
          window.$("#counterTime").text("Resend OTP in 00:" + counter);
          console.log("Timer --> " + counter);
        }
      }, 1000);
    },
    validateOtp() {
      if (this.otp == null) {
        return false;
        //this.$shareService.onErrorToast(e.message);("Please enter otp");
      }
      this.$api
        .webRequest({
          _method: "POST",
          _action: "forget-password/verify",
          _buttonId: "verify-otp",
          _hide_loader: true,
          _body: {
            otp: this.otp,
            email: this.email,    
          },
        })
        .then((res) => {
          this.token = res.token;
          localStorage.setItem("token", res.token);
          if (res.has_verified == true) {
            this.$toast.success(res.message, {
              position: 'top-right'
            });
            this.$router.push("/reset-password/" + this.token);
            this.otpProcess = true;
            this.emailProcess = false;
            this.setCounter();
          }
        })
        .catch((e) => {
          this.$toast.error(e.message, {
            position: 'top-right'
          });
          //this.$shareService.onErrorToast(e.message);(e.message);
        });
    },
    changeOtp() {
      this.otpProcess = true;
    },
    handleOnComplete(value) {
      console.log("value from otp input:", value, this.bindValue)
    },
    handleOnChange(value) {
      this.otp = value;
    },

    onReSendOtp() {
      var counterDisplay = window.$("#counterTime");
      var resendOtpDisplay = window.$("#resendOtpDisplay");
      if (counterDisplay.hasClass("d-none")) {
        counterDisplay.removeClass("d-none");
        counterDisplay.addClass("d-block");
        counterDisplay.text("");
      } else {
        counterDisplay.addClass("d-block");
      }
      if (resendOtpDisplay.hasClass("d-block")) {
        resendOtpDisplay.removeClass("d-block");
        resendOtpDisplay.addClass("d-none");
      } else {
        resendOtpDisplay.addClass("d-none");
      }

      this.onSendOtp("RESEND");
    },
    onSendOtp(type) {
      var btnId ="save-btn";
      if (type == "RESEND") {
          btnId = "resendOtpDisplay";
        var counterDisplay = window.$("#counterTime");
        counterDisplay.append(
          `<span class="spinner-border text-info" role="status"></span>`
        );
      }
      this.$api
        .webRequest({
          _method: "POST",
          _action: "forget-password",
          _buttonId: btnId,
          _hide_loader: true,
          _body: { email: this.email },
        })
        .then((res) => {
          if (res.has_mail_sended == true) {
            this.otpProcess = res.has_mail_sended;
            this.ref_no = res.ref_no;
            this.emailProcess = false;
            this.$toast.success(res.message, {
              position: 'top-right'
            });
            this.setCounter();
          }
        })
        .catch((e) => {
          this.$toast.error(e.message, {
            position: 'top-right'
          });
        });
    },
    onCompleteHandler(code) {
      console.log("code completed", code);
      this.isCodeValid = false;
      this.otp = code;
    },

    onChangedHandler(lastEnteredCode) {
      console.log("code changed", lastEnteredCode);
      this.isCodeValid = true;
    },

    onPasteHandler(code) {
      console.log("code pasted", code);
      this.otp = code;
    },
  },
};
</script>

