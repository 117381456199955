import {
    createWebHistory,
    createRouter
} from "vue-router";
import Login from "@/views/Onbording/login.vue";
import ForgetPassword from "@/views/Onbording/forgetPassword.vue";
import ResetPassword from "@/views/Onbording/resetPassword.vue";
import MainView from "@/views/main.vue";
import DashBoard from "@/views/Dashboard/dashboard.vue";
import Users from "@/views/Users/users.vue";
import Apps from "@/views/Apps/app.vue";
import EditApp from "@/views/Apps/addEditApp.vue";
import AddUser from "@/views/Users/addEditUser.vue";
import Profile from "@/views/Profile/index.vue";
import Questionnaire from "@/views/ContentAdmin/questionnaire.vue";
import AddQuestion from "@/views/ContentAdmin/addEditQuestion.vue";
import EditQuestion from "@/views/ContentAdmin/addEditQuestion.vue";
import Vitals  from "@/views/ContentAdmin/vitals.vue";
import ContentPages  from "@/views/ContentAdmin/pages.vue";
import PageSetting  from "@/views/PageSetting/setting.vue";
import userProfile  from "@/views/UserProfile/profile.vue";



const routes = [{
        path: "/",
        redirect: "/login",
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
    },
    {
        path: "/forget-password",
        name: "ForgetPassword",
        component: ForgetPassword,
    },
    {
        path: "/reset-password/:token",
        name: "ResetPassword",
        component: ResetPassword,
    },
    {
        path: "",
        name: "Main",
        component: MainView,
        meta: {
            requiresAuth: true,
        },
        children: [{
                path: "/profile",
                name: "ProfileSection",
                component: Profile,
            },
            {
                path: "/dashboard",
                name: "DashBoard",
                component: DashBoard,
            },
            {
                path: "/users",
                name: "Users",
                component: Users,
            },
            {
                path: "/apps",
                name: "Apps",
                component: Apps,
            },
            {
                path: "/edit-app/:id",
                name: "EditApp",
                component: EditApp,
            },
            {
                path: "/edit-user/:id",
                name: "AddUser",
                component: AddUser,
            },
            {
                path: "/questionnaire",
                name: "QuestionNaire",
                component: Questionnaire,
            },
            {
                path: "/add-question",
                name: "AddQuestion",
                component: AddQuestion,
            },
            {
                path: "/edit-question/:id",
                name: "EditQuestion",
                component: EditQuestion,
            },
            {
                path: "/pages",
                name: "ContentPages",
                component: ContentPages,
            },
            {
                path: "/vitals",
                name: "Vitals",
                component: Vitals,
            },
            {
                path: "/setting",
                name: "PageSetting",
                component: PageSetting,
            },
            {
                path: "/user-profile",
                name: "userProfile",
                component: userProfile,
            },
        ],
    },
];
const router = createRouter({
    history: createWebHistory(),
    routes,
});
router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (localStorage.getItem("access_token") === null) {
            next({
                path: "/login",
                params: {
                    nextUrl: to.fullPath
                },
            });
        } else {
            next();
        }
    } else {
        if (to.path === "/login") {
            if (localStorage.getItem("access_token") !== null) {
                next({
                    path: "/dashboard",
                    params: {
                        nextUrl: to.fullPath
                    },
                });
            } else {
                next();
            }
        } else {
            next();
        }
    }
});

export default router;