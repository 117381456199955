<template>
  <div class="page-card">
    <div class="row align-items-center mb-3">
      <div class="col-md-6 d-flex align-items-center gap-4">
        <h2 class="page-title">{{ $t("quetionnaries.quetionnaries") }}</h2>
        <a href="javascript:void(0)" v-on:click="addQuestion()" class="add-link ms-4">{{ $t("quetionnaries.add_question")
        }} <i class="bi bi-plus-circle-fill"></i></a>
      </div>
      <div class="col-md-6">
        <div class="d-flex justify-content-end font-12 text-uppercase gap-2 align-items-center">
          <span>show</span>
          <div class="bg-light-brand px-1">100</div>
          <span>entries</span>
        </div>
      </div>
    </div>

    <div class="table-wrap">
      <table class="table table-style">
        <thead>
          <tr>
            <th scope="col"><img src="images/sort-icon.svg" class="sort-cion"> {{ $t("quetionnaries.id") }}</th>
            <th scope="col"><img src="images/sort-icon.svg" class="sort-cion"> {{ $t("quetionnaries.name") }} </th>
            <th scope="col" class="text-center"><img src="images/sort-icon.svg" class="sort-cion">{{
              $t("quetionnaries.usage") }}</th>
            <th scope="col" class="text-center"><img src="images/sort-icon.svg" class="sort-cion">{{
              $t("quetionnaries.location") }}</th>
            <th scope="col" class="text-center">{{ $t("quetionnaries.action") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="loader">
            <td colspan="5" class="text-center">
              <div class="spinner-border spinner-color" role="status">
              </div>
            </td>
          </tr>
          <tr v-else v-for="item in questionLists" :key="item.id">
            <td>Q{{ item?.id }}</td>
            <td> <a style="cursor:pointer">{{
              item?.questions }}</a></td>

            <td style="background-color:#2B8000" class="text-center" v-if="item?.status == 'ACTIVE'"
              v-on:click="updateLitStatus($event, item)">In Use</td>
            <td class="text-center" v-if="item?.status == 'INACTIVE'" v-on:click="updateLitStatus($event, item)">
              Not Used</td>
            <td class="text-center" v-if="item?.apps">{{ item?.apps?.name + " | " + item?.apps?.location }}</td>
            <td class="text-center" v-else>-</td>
            <td>
              <div class="action-ele">
                <!-- <div class="form-check form-switch font-18">
                                    <input class="form-check-input" v-on:click="updateLitStatus($event, item.id)" :value="item.status" :checked="item.status == 'ACTIVE' ? true : false" type="checkbox" role="switch" id="flexSwitchCheckChecked">
                                </div> -->
                <!-- <a href="javascript:void(0)"><img src="images/clone.svg" width="22" alt="" /></a> -->
                <a href="javascript:void(0)" v-on:click="editQuestion(item.id)"><img src="images/icon-edit.svg" width="22"
                    alt=""></a>
                <a href="javascript:void(0)" v-on:click="openAddEditModel(item.id)"><img src="images/icon-archive.svg"
                    width="18" alt=""></a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <ErrorComponent @retry="getList(1)" ref="errorComponent" />
    </div>
    <div class="row">
      <div class="col-lg-12 mt-5 text-center">
        <div class="page-bottom-pagination text-right">
          <Pagination @page-change="pageChange" @items-per-page-change="itemsPerPageChange" ref="rolePagination" />
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="row">
    <div class="col-lg-5">
      <div class="page-card">
        <div class="row align-items-center mb-3">
          <div class="col-md-6 d-flex align-items-center gap-4">
            <h2 class="page-title">Set questions</h2>
          </div>
          <div class="col-md-6 text-end">
            <div class="dropdown card-dropdown">
              <button
                class="btn btn-secondary dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Select Q ID
              </button>
              <ul class="dropdown-menu dropdown-menu-end">
                <div class="font-medium font-14 text-dark mb-2">
                  <i class="bi bi-info-circle"></i> Warning
                </div>
                <div class="text-secondary font-14 mb-4">
                  Kiosk questionnaires are set to 3 questions
                </div>
                <div class="d-flex justify-content-between align-items-center">
                  <samp class="text-brand font-12">LEARN MORE</samp>
                  <button class="btn btn-dark-brand btn-sm">GOT IT</button>
                </div>
              </ul>
            </div>
          </div>
        </div>
        <table class="table table-style">
          <thead>
            <tr>
              <th scope="col">
                <img src="images/sort-icon.svg" class="sort-cion" /> ID
              </th>
              <th scope="col">
                <img src="images/sort-icon.svg" class="sort-cion" /> Name
              </th>
              <th scope="col" class="text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Q1</td>
              <td>Question 1</td>
              <td>
                <div class="action-ele">
                  <div class="form-check form-switch font-18">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckChecked"
                      checked
                    />
                  </div>
                  <a href=""
                    ><img src="images/icon-edit.svg" width="22" alt=""
                  /></a>
                </div>
              </td>
            </tr>
            <tr>
              <td>Q2</td>
              <td>Question 2</td>
              <td>
                <div class="action-ele">
                  <div class="form-check form-switch font-18">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckChecked"
                      checked
                    />
                  </div>
                  <a href=""
                    ><img src="images/icon-edit.svg" width="22" alt=""
                  /></a>
                </div>
              </td>
            </tr>
            <tr>
              <td>Q3</td>
              <td>Question 3</td>
              <td>
                <div class="action-ele">
                  <div class="form-check form-switch font-18">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckChecked"
                      checked
                    />
                  </div>
                  <a href=""
                    ><img src="images/icon-edit.svg" width="22" alt=""
                  /></a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-lg-7">
      <div class="page-card">
        <div class="row align-items-center mb-3 border-bottom pb-3">
          <div class="col-md-6 d-flex align-items-center gap-4">
            <div class="dropdown card-dropdown">
              <button
                class="btn btn-secondary dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
               English
              </button>
              <ul class="dropdown-menu  ">
                <li><button class="dropdown-item" type="button">English</button></li>
                <li><button class="dropdown-item" type="button">French</button></li>
                <li><button class="dropdown-item" type="button">Japnise</button></li>
              </ul>
            </div>
          </div>
          <div class="col-md-6 text-end">
            <div class="dropdown card-dropdown">
              <button
                class="btn btn-secondary dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i class="bi bi-circle-fill text-warning font-12 me-1"></i> Draft
              </button>
              <ul class="dropdown-menu dropdown-menu-end">
                <div class="font-medium font-14 text-dark mb-2">
                  <i class="bi bi-info-circle"></i> Draft
                </div>
                <div class="text-secondary font-14 mb-4">
                  All article saved here before you publish them. You can edit anything whatever you want.
                </div>
                <div class="d-flex justify-content-between align-items-center">
                  <samp class="text-brand font-12">LEARN MORE</samp>
                  <button class="btn btn-dark-brand btn-sm">GOT IT</button>
                </div>
              </ul>
            </div>
          </div>
         
        </div>

        <div class="px-4">
          <h3 class="font-18 fw-bold mb-1">How much water do you drink a day?</h3>
          <p class="mb-3 font-14 text-secondary">Last edited 25 minutes ago by <a href="" class=" text-primary">Admin Name</a></p>
          <img src="images/editor.png" class="img-fluid mb-3" alt=""/>
          <button class="btn btn-dark-brand mb-4">Save draft</button>
        </div>
      </div>
    </div>
  </div> -->
  <div class="modal fade" id="addModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">Confirmation </h1>
          <button type="button" class="btn-close" v-on:click="closeAlertModal()"></button>
        </div>
        <div class="modal-body form-style">
          <div class="row pb-5">
            <div class="col-md-12">
              <h6>Are you sure you want to delete?</h6>
            </div>
          </div>
          <div class="modal-footer border-0 justify-content-start">
            <button id="save-btn" @click="closeModle()" type="submit" class="btn btn-dark-brand px-4">Cancle</button>
            <button type="button" v-on:click="onDelete()" class="btn btn-light-brand px-4"
              data-bs-dismiss="modal">Delete</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.spinner-color {
  color: "#351D4E"
}
</style>
<script>
import Pagination from "@/components/Pagination";
import ErrorComponent from "@/components/ErrorComponent";
export default {
  name: "QuestionNaire",
  components: {
    ErrorComponent,
    Pagination,

  },
  data() {
    return {
      currentPage: 1,
      totalPage: 0,
      itemsPerPage: 10,
      page_id: null,
      filterObj: {
        keyword: "",
        offset: 1,
        sort_by: "created_at",
        sort_order: "desc",
        row_per_page: 10,
        level: "",
        status: ""
      },
      loader: false,
      questionLists: [],
      question_id: null
    };
  },
  mounted() {
    this.getList(1);
  },
  methods: {
    openAddEditModel(id) {
      window.$("#addModal").modal("show");
      this.question_id = id;
    },
    editAppDetails(id) {
      this.$router.push("/edit-app/" + id)
    },
    closeAlertModal() {
      window.$("#addModal").modal("hide");
      this.question_id = null;
    },
    addQuestion() {
      this.$router.push("/add-question");
    },
    editQuestion(id) {
      this.$router.push("/edit-question/" + id);
    },
    updateStatus(e) {
      var status = e.target.checked;
      console.log(status);
      if (status == true) {
        this.users.status = "ACTIVE";
      }
      if (status == false) {
        this.users.status = "INACTIVE";
      }
      console.log(status, this.users.status);
    },

    pageChange(page) {
      this.getList(page);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.rolePagination.itemsPerPage;
      this.getList(1);
    },
    retry() {
      this.getList(1);
    },
    getList(page, sortBy, orderBy) {
      this.filterObj.offset = page - 1;
      this.currentPage = page;
      this.filterObj.row_per_page = this.itemsPerPage;

      if (sortBy) {
        this.filterObj.sort_by = sortBy;
      }
      if (orderBy) {
        this.filterObj.sort_order = orderBy;
      }

      this.loader = true;

      this.list = [];
      var method = "GET";
      this.$api
        .webRequest({
          _method: method,
          _action: "questions",
          _body: this.filterObj,
        })
        .then((res) => {
          this.questionLists = res.list;
          this.loader = false;
          // this.access = res.access;
          if (page == 1) {
            if (res.count != null && res.count > 0) {
              if (this.$refs.rolePagination) {
                this.$refs.rolePagination.setTotalCount(res.count);
              }
            } else {
              if (this.$refs.rolePagination) {
                this.$refs.rolePagination.setTotalCount(0);
              }
            }
          }
          if (!res.list || res.list.length <= 0) {
            if (this.$refs.errorComponent) {
              this.$refs.errorComponent.setTitle("Sorry, There is no record found.");
            }
          } else {
            if (this.$refs.errorComponent) {
              if (this.$refs.errorComponent.isShowMessage()) {
                this.$refs.errorComponent.setTitle();
              }
            }
          }
          if (this.$refs.rolePagination) {
            this.$refs.rolePagination.currentPage = this.currentPage;
          }
        })
        .catch((e) => {
          this.$toast.error(e.message, {
            position: "top-right"
          });
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setTitle();
          }
        });
    },
    sorting(e, sortBy) {
      var orderBy = "ASC";

      var className = "sorting_asc";
      var classes = e.target.className;
      if (classes.includes("sorting_asc")) {
        className = "sorting_desc";
      }

      window.$(".sorting_asc").removeClass("sorting_asc");
      window.$(".sorting_desc").removeClass("sorting_desc");

      if (className == "sorting_asc") {
        window.$(e.target).addClass("sorting_asc").removeClass("sorting_desc");
        orderBy = "ASC";
      } else if (className == "sorting_desc") {
        window.$(e.target).addClass("sorting_desc").removeClass("sorting_asc");
        orderBy = "DESC";
      }
      this.getList(1, sortBy, orderBy);
    },

    updateLitStatus(e, item) {
      var obj = {};
      if (item.status == "ACTIVE") {
        obj.status = "INACTIVE";
      }
      if (item.status == "INACTIVE") {
        obj.status = "ACTIVE";
      }
      this.$api
        .webRequest({
          _method: "PUT",
          _action: "page/" + item.id + "/status",
          _body: obj,
        }).then((res) => {
          this.$toast.success(res.message, {
            position: "top-right"
          });
          this.getList(1);
          this.closeAddEditModel();
        })
        .catch((e) => {
          this.$toast.error(e.message, {
            position: "top-right"
          });
        });
    },
    onDelete() {
      this.$api
        .webRequest({
          _method: "DELETE",
          _action: "delete/" + this.question_id,
        }).then((res) => {
          this.$toast.success(res.message, {
            position: "top-right"
          });
          this.getList(this.currentPage);
          this.question_id = null;
        })
        .catch((e) => {
          this.$toast.error(e.message, {
            position: "top-right"
          });
        });
    },
   


  },
};
</script>

<style>
.spinner-color {
  color: "#351D4E"
}
</style>