<template>
  <div class="mt-3">
    <a href="javascipt:void(0)" class="text-secondary fw-light" v-on:click="$router.go(-1)"> &#60; Back </a>
  </div>
  <!-- <div class="row" v-if="loader">
   <div class="col-lg-12 d-flex justify-content-center" style="height: 400px !important;">
    <div class="spinner-border spinner-color" role="status">
              </div>
   </div>
  </div> -->
  <div class="row">
    <div class="col-lg-8">
      <div class="row mb-3">
        <div class="col-md-6">
          <div class="page-card">
            <div class="d-flex align-items-center gap-2">
              <div class=" border-end border-danger pe-3">
                <p class=" text-secondary fw-medium">Scans</p>
                <p class="h2">{{ complete_result }} | <span class="text-danger">{{ failed_result }}</span></p>
                <!-- <p class=" text-secondary font-14">Some text</p> -->
              </div>
              <div>
                <ul class=" text-secondary font-12 mb-0">
                  <li>Failing reason 1</li>
                  <li>Failing reason 2</li>
                  <li>Failing reason 3</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="page-card">
            <div class="d-flex align-items-center gap-2">
              <div class=" border-end pe-3">
                <p class=" text-secondary fw-medium">Shared</p>
                <p class="h2">{{ shared_result }}</p>
                <!-- <p class=" text-secondary font-14">Some text</p> -->
              </div>
              <!-- <div>
                <ul class=" text-secondary font-12 mb-0">
                  <li>Failing reason 1</li>
                  <li>Failing reason 2</li>
                  <li>Failing reason 3</li>
                </ul>
              </div> -->
            </div>
          </div>
        </div>
      </div>

      <div class="page-card mb-3">
        <div class="row align-items-center mb-3 border-bottom">
          <div class="col-md-6 d-flex align-items-center gap-4">
            <h2 class="page-title">User activities</h2>
          </div>
          <div class="col-md-6 text-end">
            <select class="form-select border-0 w-auto ms-auto font-14 fw-light" v-on:change="onUserActivity(type)"
              v-model="type" aria-label="Default select example">
              <option selected value="WEEKLY">Last 7 Days (Weekly)</option>
              <option value="DAILY">Daily</option>
              <option value="MONTHLY">Monthly</option>
              <option value="YEARLY">Yearly</option>
            </select>
          </div>
        </div>
        <div class="card">
          <div class="card-header" v-if="type == 'WEEKLY'">
            <VueDatePicker class="form-control" @closed="onUserActivity('WEEKLY')" v-model="week" week-picker
              :enable-time-picker="false"></VueDatePicker>
          </div>
          <div class="card-header" v-if="type == 'MONTHLY'">
            <VueDatePicker class="form-control" month-picker @closed="onUserActivity('MONTHLY')" v-model="month"
              :enable-time-picker="false"></VueDatePicker>
          </div>
          <div class="card-header" v-if="type == 'YEARLY'">
            <VueDatePicker class="form-control" year-picker @closed="onUserActivity('YEARLY')" v-model="year"
              :enable-time-picker="false"></VueDatePicker>
          </div>
          <div class="card-header" v-if="type == 'DAILY'">
            <VueDatePicker class="form-control" v-model="today_date" @closed="onUserActivity('DAILY')"
              :enable-time-picker="false">
            </VueDatePicker>
          </div>
          <div class="card-body" id="graph-container">
            <canvas id="userActivity" class="chartjs-render-monitor"></canvas>
          </div>
        </div>
        <div class="d-flex flex-row pt-3">
          <div class="form-check mx-2">
            <input class="form-check-input" type="radio" name="complete" id="complete1" style="background-color: #2B8000;"
              checked>
            <label class="form-check-label" for="complete1">
              Completed
            </label>
          </div>
          <div class="form-check mx-2">
            <input class="form-check-input" type="radio" name="failed" style="background-color: #F2233B;" id="failed1"
              checked>
            <label class="form-check-label" for="failed1">
              Failed
            </label>
          </div>
          <div class="form-check mx-2">
            <input class="form-check-input" type="radio" name="b2b" id="b2b1">
            <label class="form-check-label" for="b2b1">
              B2B Views
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="page-card">
        <div class="text-center mt-3 pb-3 mb-3 border-bottom">
          <img src="/images/placeholder.png" width="150" height="150" class=" object-fit-cover rounded-circle mb-3"
            alt="">
          <p class=" font-24 fw-bold mb-1">{{ info?.full_name }}</p>
          <p class=" text-secondary" v-if="info.email_verified_at != null">Mail</p>
          <p class=" text-secondary" v-if="info.phone_verified_at != null">Phone</p>
          <!-- <button class="btn btn-dark-brand px-5 mb-3" href="javascript:void(0)" v-on:click="openAddEditModel()">Edit Profile</button> -->
          <button id="edit-User-btn" class="btn btn-dark-brand px-5 mb-3" @click="onUpdateDetails()" href="javascript:void(0)">Edit Profile</button>
        </div>
        <table class=" table table-borderless font-14 mb-lg-5">
          <tr>
            <td class="text-secondary">Email</td>
            <td class="text-end" v-if="info.email">{{ info?.email }}</td>
            <td class="text-end" v-else>-</td>
          </tr>
          <tr>
            <td class="text-secondary">Phone</td>
            <td class="text-end" v-if="info.phone_number">{{ info?.phone_number }}</td>
            <td class="text-end" v-else>-</td>
          </tr>
          <tr>
            <td class="text-secondary">Date of birth</td>
            <td class="text-end" v-if="info.body_info">{{
              $helperService.getFormatedDateWithoutTime(info?.body_info.date_of_birth) }}</td>
            <td class="text-end" v-else>-</td>
          </tr>
          <tr>
            <td class="text-secondary">Account created</td>
            <td class="text-end" v-if="info.created_at">{{ $helperService.getFormatedDateAndTime(info?.created_at) }}</td>
            <td class="text-end" v-else>-</td>
          </tr>
          <tr>
            <td class="text-secondary">Status</td>
            <td class="text-end" v-if="info.status">{{ $helperService.getTitleCase(info?.status) }}</td>
            <td class="text-end" v-else>-</td>
          </tr>
        </table>

        <p class=" text-secondary mb-2">Note</p>
        <p v-if="info.note">{{ info?.notes }}</p>
        <p v-else>-</p>
      </div>
    </div>
  </div>
  <div class="modal fade" id="addModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
      <div class="modal-content">

        <div class="modal-header">
          <h1 class="modal-title fs-5" v-if="user_id != null" id="exampleModalLabel">Edit user</h1>
          <h1 class="modal-title fs-5" v-else id="exampleModalLabel">Create user</h1>
          <button type="button" class="btn-close" v-on:click="closeAddEditModel()"></button>
        </div>
        <div class="modal-body form-style">
          <Form @submit="onSave()" ref="addEditUser">
            <div class="row">
              <div class="col-md-2" v-if="user_id">
                <p class=" text-secondary font-14">User ID <br>
                  <span class="font-medium">123</span>
                </p>
              </div>
              <div class="col-md-10">
                <div class="form-floating mb-2">
                  <Field :disabled="hasArchiveRecord" type="text" class="form-control border-radius" name="first_name" v-model="users.first_name"
                    rules="required:first name" :validateOnInput="true" id="first_name" placeholder="First Name" />
                  <label for="first_name">First Name</label>
                  <ErrorMessage name="first_name" class="validation-msg" />
                </div>
                <div class="form-floating mb-2">
                  <Field :disabled="hasArchiveRecord" type="text" class="form-control border-radius" name="last_name" v-model="users.last_name"
                    rules="required:last name" :validateOnInput="true" id="last_name" placeholder="Last Name" />
                  <label for="last_name">Last name</label>
                </div>
                <ErrorMessage name="last_name" class="validation-msg" />
                <div class="input-group mb-2" v-if="user_id == null">
                  <span class="input-group-text border-radius" id="inputGroup-sizing-sm"><img src="/images/lock-icon.svg" width="18"
                      alt=""></span>
                  <div class="form-floating">
                    <Field :disabled="hasArchiveRecord" type="password" class="form-control border-radius" name="password" v-model="users.password"
                      rules="required:password" :validateOnInput="true" id="password" placeholder="password" />
                    <label for="lName">Password</label>
                    <i id="button" alt="" class="show-icon fa fa-eye-slash field-icon" style=" top:15px !important"></i>
                  </div>
                </div>
                <ErrorMessage name="password" class="validation-msg" />
                <div class="input-group mb-2">
                  <div class="form-floating">
                    <Field :disabled="hasArchiveRecord" v-slot="{ field }" name="level" rules="required:type,true" :validateOnInput="true"
                      v-model="users.type">
                      <select :disabled="hasArchiveRecord" v-bind="field" class="form-select border-radius" id="floatingSelectGrid" v-model="users.type">
                        <option value="EMAIL">Email</option>
                        <option value="PHONE_NUMBER">Phone Number</option>
                      </select>
                      <label for="floatingSelectGrid">Type</label>
                    </Field>
                  </div>
                </div>

                <div class="input-group mb-2" v-if="users.type == 'EMAIL'">
                  <span class="input-group-text border-radius" id="inputGroup-sizing-sm"><img src="/images/email-icon.svg" width="18"
                      alt=""></span>
                  <div class="form-floating">
                    <Field :disabled="hasArchiveRecord" type="email" class="form-control border-radius" name="email" v-model="users.email"
                      rules="required:email|email" :validateOnInput="true" id="email" placeholder="Email" />
                    <label for="email"> Email</label>
                    <ErrorMessage name="email" class="validation-msg" />
                  </div>
                </div>
                <div class="input-group mb-2" v-if="users.type == 'PHONE_NUMBER'">
                  <span class="input-group-text border-radius" id="inputGroup-sizing-sm"><img src="/images/call-icon.svg" width="18"
                      alt=""></span>
                  <div class="form-floating">
                    <Field :disabled="hasArchiveRecord" type="text" class="form-control border-radius" name="phone_number" v-model="users.phone_number"
                      rules="required:phone number" :validateOnInput="true" id="phone_number"
                      placeholder="Phone Number" />
                    <label for="phone_number">Phone Number</label>
                    <ErrorMessage name="phone_number" class="validation-msg" />
                  </div>
                </div>
                <label for="phone_number">Date Of Birth</label>
                <div class="input-group mb-2">
                  <span class="input-group-text border-radius" id="inputGroup-sizing-sm"><img src="/images/calendar-icon.svg" width="18"
                      alt=""></span>
                      <Field class="form-control border-radius" name="date_of_birth" id="date_of_birth"  rules="required:date of birth,true|ageValidation" v-model="users.date_of_birth" v-slot="{date}">
                        <VueDatePicker :disabled="hasArchiveRecord" v-bind="date" name="date_of_birth" class="form-control border-radius" v-model="users.date_of_birth" hideInputIcon :clearable="false"
                        format="yyyy-MM-dd" placeholder="Select Date" :enable-time-picker="false"></VueDatePicker>
                      </Field>
                    </div>
                    <ErrorMessage name="date_of_birth" class="validation-msg" />
              </div>
            </div>
            <hr class=" border-secondary-subtle">
            <div class="row">
              <div class="col-md-4">
                <label for="" class="font-18 text-secondary mb-3">Gender at birth</label>
                <div class="form-check">
                  <input class="form-check-input" type="radio" :disabled="hasArchiveRecord" v-model="users.gender" name="genderBtn" value="FEMALE"
                    id="Female" :checked="users.gender == 'FEMALE' ? true : false">
                  <label class="form-check-label" for="Female">
                    Female
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" :disabled="hasArchiveRecord" type="radio" v-model="users.gender" value="MALE" name="genderBtn"
                    id="Male" :checked="users.gender == 'MALE' ? true : false">
                  <label class="form-check-label" for="Male">
                    Male
                  </label>
                </div>
              </div>
              <div class="col-md-8">
                <div class="d-flex align-items-center gap-3 mb-3">
                  <label for="" class="font-18 text-secondary mb-0">Status</label>
                  <label class="font-14">Not active</label>
                  <div class="form-check form-switch">
                    <input class="form-check-input" :disabled="hasArchiveRecord" v-on:click="updateStatus($event)" type="checkbox"
                      :checked="users.status == 'ACTIVE' ? true : false" role="switch" id="flexSwitchCheckDefault">
                    <label class="form-check-label" for="flexSwitchCheckDefault">Active</label>
                  </div>
                </div>
                <div v-if="user_id != null">
                  <label for="phone_number">Created At</label>
                  <div class="input-group flex-nowrap mb-3 input-group-lg">
                    <span class="input-group-text border-radius " id="addon-wrapping"><img src="/images/calendar-icon.svg" width="18"
                        alt=""></span>
                    <VueDatePicker :disabled="hasArchiveRecord == true ? hasArchiveRecord : (user_id ? true: false )"  class="form-control border-radius" v-model="users.created_at" :enable-time-picker="false">
                    </VueDatePicker>
                  </div>
                  <label for="phone_number">Expired At</label>
                  <div class="input-group flex-nowrap mb-3 input-group-lg">
                    <span class="input-group-text border-radius bg-warning" id="addon-wrapping"><img src="/images/calendar-icon.svg"
                        width="18" alt=""></span>
                    <VueDatePicker :disabled="hasArchiveRecord" class="form-control border-radius" v-model="users.expired_at" :enable-time-picker="false">
                    </VueDatePicker>
                  </div>
                </div>
              </div>
            </div>
            <hr class=" border-secondary-subtle">
            <div class="form-floating mb-2">
              <textarea :disabled="hasArchiveRecord" v-model="users.notes" class="form-control border-radius" rows="5" cols="6" id="Note" placeholder="Note" />
              <label for="Note">Note</label>
            </div>
            <div class="modal-footer border-0 justify-content-start"  v-show="hasArchiveRecord == false">
              <button id="save-user-btn" type="submit" class="btn btn-dark-brand px-4">Save</button>
              <button type="button" v-on:click="closeAddEditModel()" class="btn btn-light-brand px-4"
                data-bs-dismiss="modal">Close</button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.spinner-color {
  color: "#351D4E"
}
.field-icon {
  float: right;
  margin-right: 8px;
  margin-top: -53px;
  position: relative;
  z-index: 2;
  cursor:pointer;
}
</style>
<script>
import moment from "moment";
import Chart from 'chart.js/auto';
Chart.register("line");
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { Form, Field, ErrorMessage } from "vee-validate";
import { ref } from 'vue';
import $ from "jquery";
export default {
  name: "UserProfile",
  components: {
    //   Title,
    // Tooltip,
    // Legend,
    // BarElement,
    // CategoryScale,
    // LinearScale
    Form, Field, ErrorMessage,

    VueDatePicker
  },
  data() {
    return {
      id: this.$route.params.id,
      total_count: null,
      total_title: null,
      userActivity: "userActivity",
      type: "WEEKLY",
      week: [],
      year: ref(new Date().getFullYear()),
      today_date: ref(new Date()),
      month: ref({
        month:new Date().getMonth(),
        year:new Date().getFullYear()
      }),
      userChart: "",
      filterObj: {
        from_date: "",
        to_date: "",
        user_id: this.$route.params.id,
        graph_type: "FAILED"
      },
      user_id: this.$route.params.id,
      complete_left_title: [],
      failed_left_title: [],
      common_bottom_title: [],
      complete_scan: "",
      failed_result: "",
      shared_result: 0,
      info: {},
      loader: false,
      types: [
        {
          "name": "Email",
          "value": "EMAIL"
        },
        {
          "name": "Phone number",
          "value": "PHONE_NUMBER"
        },],
      users: {
        type: "EMAIL"
      },
      hasArchiveRecord:false,
      passwordFieldType:"password"

    };
  },
  mounted() {

    $(document).ready(function () {
      $(".show-icon").on("click", function (event) {
        event.preventDefault();
        console.log("type : ", $(this).parent().find("input").attr("type"));
        var input = $(this).parent().find("input");
        if (input.attr("type") == "text") {
          input.attr("type", "password");
          $(this).parent().find("i").removeClass("fa-eye").addClass("fa-eye-slash");
        } else if (input.attr("type") == "password") {
          input.attr("type", "text");
          $(this).parent().find("i").removeClass("fa-eye-slash").addClass("fa-eye");
        }
      });
    });
    let date_today = new Date();


    let first_day_of_the_week = new Date(date_today.setDate(date_today.getDate()
      - date_today.getDay()));

    let last_day_of_the_week = new Date(date_today.setDate(date_today.getDate()
      - date_today.getDay() + 6));
    
    this.week.push(first_day_of_the_week, last_day_of_the_week);
    this.filterObj.from_date = first_day_of_the_week;
    this.filterObj.to_date = last_day_of_the_week;
    this.onUserActivity(this.type);
    this.onUserInfo();
  },
  methods: {
 
    onClearForm(formRefName) {
      this.$refs[formRefName].resetForm();
    },
    openAddEditModel() {
      window.$("#addModal").modal("show");
    },
    closeAddEditModel() {
      this.onClearForm("addEditUser");
      this.users.date_of_birth = null;
      this.users.created_at = null;
      this.users.expired_at = null;
      this.user_id = null;
      this.users = {};
      window.$("#addModal").modal("hide");
    },
    updateStatus(e) {
      var status = e.target.checked;
      console.log(status);
      if (status == true) {
        this.users.status = "ACTIVE";
      }
      if (status == false) {
        this.users.status = "INACTIVE";
      }
      console.log(status, this.users.status);
    },
    clearfilterObj(){
      this.filterObj.from_date = "";
      this.filterObj.to_date = "";
    },
    onUserActivity(type) {
      this.type = type;
      if (this.type == "WEEKLY") {
        this.filterObj.from_date = moment(String(this.week[0])).format(
          "Y-MM-DD"
        );
        this.filterObj.to_date = moment(String(this.week[1])).format(
          "Y-MM-DD"
        );

        console.log(this.filterObj);
      }
      if (this.type == "DAILY") {
        this.filterObj.from_date = moment(String(this.today_date)).format(
          "Y-MM-DD"
        );
        this.filterObj.to_date = moment(String(this.today_date)).format(
          "Y-MM-DD"
        );
        console.log(this.filterObj);
      }

      if (this.type == "MONTHLY") {
        let month = this.getNumberOfMonths(this.month.month);
        console.log("from Monthly :",month);
        let year = this.month.year;
        let date = new Date(year,month,1), y = date.getFullYear(), m = date.getMonth();
        let firstDay = new Date(y, m, 1);
        let lastDay = new Date(y, m + 1, 0);
        console.log(firstDay, lastDay); 
        this.filterObj.from_date = moment(firstDay).format("Y-MM-DD");
        this.filterObj.to_date = moment(lastDay).format("Y-MM-DD");
        console.log(this.filterObj, this.month);

      }

      if (this.type == "YEARLY") {

        this.filterObj.year = moment(String(this.year)).format("Y");
        const firstDay = new Date(this.filterObj.year, 0, 1);
        const lastDay = new Date(this.filterObj.year, 11, 31);
        this.filterObj.from_date = moment(firstDay).format("Y-MM-DD");
        this.filterObj.to_date = moment(lastDay).format("Y-MM-DD");
        console.log(this.filterObj, this.year);

      }
      window.$("#userActivity").remove();
      window.$('#graph-container').append('<canvas id="userActivity" class="chartjs-render-monitor"><canvas>');
      this.loader = true;
      this.$api
        .webRequest({
          _method: "GET",
          _action: "user/" + this.type + "/graph",
          _body: this.filterObj
        })
        .then(res => {
          this.loader = false;
          if (res.complete_scan && res.failed_scan) {
            this.failed_result = res.failed_count;
            this.complete_result = res.complete_count;
            this.shared_result = res.shared_count;
            var obj = {
              failed_scan_title: res.failed_scan_title,
              failed_color_code: res.failed_color_code,
              success_color_code: res.success_color_code,
              success_scan_title: res.success_scan_title,
            }
            this.userActivityChart(res.complete_scan, res.failed_scan, obj);
          } else {
            var temObj = {
              failed_scan_title: "Failed Scan",
              failed_color_code: "#F2233B",
              success_color_code: "Complete Scan",
              success_scan_title: "#2B8000",
            }
            var complete_scan = [];
            var failed_scan = [];
            this.userActivityChart(complete_scan, failed_scan, temObj);
          }
          this.clearfilterObj();
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
    },
    userActivityChart(complete_scan, failed_scan, obj) {

      this.complete_left_title = [];
      this.failed_left_title = [];
      this.common_bottom_title = [];

      for (var i = 0; i < complete_scan.length; i++) {
        if (complete_scan[i].complete) {
          this.complete_left_title.push(complete_scan[i].complete);
        }
        this.common_bottom_title.push(complete_scan[i].graph_date);
      }
      for (var j = 0; j < failed_scan.length; j++) {
        if (failed_scan[j].failed) {
          this.failed_left_title.push(failed_scan[j].failed);
        }
      }

      console.log("title from pie :", this.bottom_title);
      if (document.getElementById(this.userActivity)) {
        var ctx = document.getElementById(this.userActivity).getContext("2d");
        // var $this = this;
        this.userChart = new Chart(ctx, {
          type: "line",
          data: {
            datasets: [
              {
                label: obj.success_scan_title,
                data: this.complete_left_title,
                fill: false,
                backgroundColor: [obj.success_color_code],
                borderColor: obj.success_color_code,
                hoverOffset: 4,
                tension: 0.1,
                responsive: true,
              }, {
                label: obj.failed_scan_title,
                data: this.failed_left_title,
                fill: false,
                backgroundColor: [obj.failed_color_code],
                borderColor: obj.failed_color_code,
                hoverOffset: 4,
                tension: 0.1,
                type: "line",
                responsive: true,
              }
            ],
            labels: this.common_bottom_title
          },
          options: {
            tooltips: {
              callbacks: {
                title: function (t, d) {
                  let title = d.datasets[t[0].datasetIndex].label;
                  return title;
                },
                label: function (t, d) {
                  // let title = d.datasets[t.datasetIndex].label;
                  let label = d.labels[t.index];
                  let value = d.datasets[t.datasetIndex].data[t.index];
                  return label + ": " + value + "%";
                }
              }
            }
          }
        });
      }
    },
    onUserInfo() {
      var method = "GET";
      this.$api
        .webRequest({
          _method: method,
          _action: "user/" + this.id,
          _hide_loader: true,
        }).then((res) => {
          this.users = res.info;

          if(res?.info?.deleted_at != null){
            this.hasArchiveRecord = true;
          }
          this.info = res.info;
          // console.log("the user details are the following :",this.users)
          
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
    },

    getNumberOfMonths(value){
      return value;
    },
    onSave() {
      if (this.users.date_of_birth) {
        this.users.date_of_birth = moment(this.users.date_of_birth).format(
          "yyyy-MM-DD"
        );
      }


      if (this.users.expired_at) {
        this.users.expired_at = moment(this.users.expired_at).format(
          "yyyy-MM-DD"
        );
      }
      if (this.users.type === "EMAIL") {
        delete this.users.phone_number;
      }
      if (this.users.type === "PHONE_NUMBER") {
        delete this.users.email;
      }
      console.log("data from the user:", this.users);
      var method = "POST";
      var url = "user";
      if (this.user_id != null) {
        method = "PUT";
        url = "user/" + this.user_id + "/update";
      }
      this.$api
        .webRequest({
          _method: method,
          _action: url,
          _body: this.users,
          _buttonId: "save-user-btn",
        }).then((res) => {
          this.$toast.success(res.message, { position: "top-right" });
          this.closeAddEditModel();
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
    },
    onUpdateDetails() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "user/" + this.id,
          _buttonId:"edit-User-btn"
        }).then((res) => {
          this.users = res.info;
          this.user_id = res.info.uuid;
          if (res.info.body_info != null) {
            this.users.gender = res.info.body_info.gender;
            if (res.info.body_info.date_of_birth) {
              this.users.date_of_birth = moment(res.info.body_info.date_of_birth).format(
                "yyyy-MM-DD"
              );
            }
          }

          this.openAddEditModel();
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
    },
  },
};
</script>
  