<template>
    <div class="mt-3">
            <a  class="text-secondary fw-light" v-on:click="$router.go(-1)"> &#60; Back </a>
        </div>
    <div class="page-card">
        
        <div class="modal-body form-style">
            <Form @submit="onSave()" ref="addEditApp">
                <div class="row">
                    <div class="col-md-6 pb-3">
                        <Field v-slot="{ app_id }" name="app_id" rules="required:app,true" :validateOnInput="true"
                            v-model="question.app_id">
                            <Multiselect v-bind="app_id" ref="level_multiselect" mode="single" trackBy="id" label="name"
                                valueProp="id" placeholder="Select Type" class="form-control" v-model="question.app_id"
                                :options="appLists" :createOption="true" />
                        </Field>
                        <ErrorMessage name="app_id" class="validation-msg" />
                    </div>
                    <div class="col-md-6 ">
                        <div class="form-floating mb-2">
                            <Field type="text" class="form-control" name="name" v-model="question.questions"
                                rules="required:first name" :validateOnInput="true" id="name" placeholder="First Name" />
                            <label for="name">Question</label>
                            <ErrorMessage name="name" class="validation-msg" />
                        </div>
                    </div>

                    <div class="col-md-12 ">
                        <div class="form-floating mb-2">
                            <Field type="text" class="form-control" name="location" v-model="question.impact"
                                rules="required:location" :validateOnInput="true" id="location" placeholder="Last Name" />
                            <label for="location">Impact</label>
                        </div>
                        <ErrorMessage name="location" class="validation-msg" />
                    </div>
                    <div class="col-md-12">
                        <a href="javascript:void(0)" v-on:click="addMoreOption()" class="add-link ms-4" style="float: right !important;">{{
                            $t("quetionnaries.add_option") }} <i class="bi bi-plus-circle-fill"></i></a>

                        <table class="table table-style"  v-if="optionLists.length != '0'">
                            <thead>
                                <tr>
                                    <th scope="col"><img src="/images/sort-icon.svg" class="sort-cion"> {{
                                        $t("quetionnaries.option") }}
                                    </th>
                                    <th scope="col" class="text-center">{{ $t("quetionnaries.action") }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="loader">
                                    <td colspan="5" class="text-center">
                                        <div class="spinner-border spinner-color" role="status">
                                        </div>
                                    </td>
                                </tr>
                                <tr v-else v-for="(item, index) in optionLists" :key="item.id">
                                    <td>{{ item?.option }}</td>
                                    <td>
                                        <div class="action-ele">
                                            <!-- <div class="form-check form-switch font-18">
                                      <input class="form-check-input" v-on:click="updateLitStatus($event, item.id)" :value="item.status" :checked="item.status == 'ACTIVE' ? true : false" type="checkbox" role="switch" id="flexSwitchCheckChecked">
                                  </div> -->
                                            <a href="javascript:void(0)" v-on:click="editMoreOption(item,index)"><img
                                                    src="/images/icon-edit.svg" width="22" alt=""></a>
                                            <a href="javascript:void(0)" @click="onRemoveOption(index)"><img
                                                    src="/images/icon-archive.svg" width="18" alt=""></a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
                <button id="save-btn" type="submit" class="btn btn-dark-brand px-4">Save</button>
                <button type="button" v-on:click="$router.go(-1)" class="btn mx-2 btn-light-brand px-4"
                    >Close</button>
            </Form>
        </div>
    </div>

    <!-- <div class="row">
      <div class="col-lg-5">
        <div class="page-card">
          <div class="row align-items-center mb-3">
            <div class="col-md-6 d-flex align-items-center gap-4">
              <h2 class="page-title">Set questions</h2>
            </div>
            <div class="col-md-6 text-end">
              <div class="dropdown card-dropdown">
                <button
                  class="btn btn-secondary dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Select Q ID
                </button>
                <ul class="dropdown-menu dropdown-menu-end">
                  <div class="font-medium font-14 text-dark mb-2">
                    <i class="bi bi-info-circle"></i> Warning
                  </div>
                  <div class="text-secondary font-14 mb-4">
                    Kiosk questionnaires are set to 3 questions
                  </div>
                  <div class="d-flex justify-content-between align-items-center">
                    <samp class="text-brand font-12">LEARN MORE</samp>
                    <button class="btn btn-dark-brand btn-sm">GOT IT</button>
                  </div>
                </ul>
              </div>
            </div>
          </div>
          <table class="table table-style">
            <thead>
              <tr>
                <th scope="col">
                  <img src="images/sort-icon.svg" class="sort-cion" /> ID
                </th>
                <th scope="col">
                  <img src="images/sort-icon.svg" class="sort-cion" /> Name
                </th>
                <th scope="col" class="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Q1</td>
                <td>Question 1</td>
                <td>
                  <div class="action-ele">
                    <div class="form-check form-switch font-18">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckChecked"
                        checked
                      />
                    </div>
                    <a href=""
                      ><img src="images/icon-edit.svg" width="22" alt=""
                    /></a>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Q2</td>
                <td>Question 2</td>
                <td>
                  <div class="action-ele">
                    <div class="form-check form-switch font-18">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckChecked"
                        checked
                      />
                    </div>
                    <a href=""
                      ><img src="images/icon-edit.svg" width="22" alt=""
                    /></a>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Q3</td>
                <td>Question 3</td>
                <td>
                  <div class="action-ele">
                    <div class="form-check form-switch font-18">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckChecked"
                        checked
                      />
                    </div>
                    <a href=""
                      ><img src="images/icon-edit.svg" width="22" alt=""
                    /></a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-lg-7">
        <div class="page-card">
          <div class="row align-items-center mb-3 border-bottom pb-3">
            <div class="col-md-6 d-flex align-items-center gap-4">
              <div class="dropdown card-dropdown">
                <button
                  class="btn btn-secondary dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                 English
                </button>
                <ul class="dropdown-menu  ">
                  <li><button class="dropdown-item" type="button">English</button></li>
                  <li><button class="dropdown-item" type="button">French</button></li>
                  <li><button class="dropdown-item" type="button">Japnise</button></li>
                </ul>
              </div>
            </div>
            <div class="col-md-6 text-end">
              <div class="dropdown card-dropdown">
                <button
                  class="btn btn-secondary dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i class="bi bi-circle-fill text-warning font-12 me-1"></i> Draft
                </button>
                <ul class="dropdown-menu dropdown-menu-end">
                  <div class="font-medium font-14 text-dark mb-2">
                    <i class="bi bi-info-circle"></i> Draft
                  </div>
                  <div class="text-secondary font-14 mb-4">
                    All article saved here before you publish them. You can edit anything whatever you want.
                  </div>
                  <div class="d-flex justify-content-between align-items-center">
                    <samp class="text-brand font-12">LEARN MORE</samp>
                    <button class="btn btn-dark-brand btn-sm">GOT IT</button>
                  </div>
                </ul>
              </div>
            </div>
           
          </div>
  
          <div class="px-4">
            <h3 class="font-18 fw-bold mb-1">How much water do you drink a day?</h3>
            <p class="mb-3 font-14 text-secondary">Last edited 25 minutes ago by <a href="" class=" text-primary">Admin Name</a></p>
            <img src="images/editor.png" class="img-fluid mb-3" alt=""/>
            <button class="btn btn-dark-brand mb-4">Save draft</button>
          </div>
        </div>
      </div>
    </div> -->
    <div class="modal fade" id="addModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
        <div class="modal-content">

            <div class="modal-header">
                <h1 class="modal-title fs-5" v-if="current_index != null" id="exampleModalLabel">Edit Option </h1>
                <h1 class="modal-title fs-5" v-else id="exampleModalLabel">Add Option</h1>
                <button type="button" class="btn-close" v-on:click="closeAddEditModel()"></button>
            </div>
            <div class="modal-body form-style">
                <Form @submit="updateOptionList()" ref="addEditOption">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-floating mb-2">
                                <Field type="text" class="form-control" name="name" v-model="newOption.option" rules="required:first name" :validateOnInput="true" id="name" placeholder="First Name" />
                                <label for="name">Name</label>
                                <ErrorMessage name="name" class="validation-msg" />
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer border-0 justify-content-start">
                        <button id="save-btn" type="submit" class="btn btn-dark-brand px-4">Save</button>
                        <button type="button" v-on:click="closeAddEditModel()" class="btn btn-light-brand px-4" data-bs-dismiss="modal">Close</button>
                    </div>
                </Form>
            </div>
        </div>
    </div>
</div>
</template>
<style>
.spinner-color {
    color: "#351D4E"
}
</style>
<script>
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css"
import {
    Form,
    Field,
    ErrorMessage
} from "vee-validate";
export default {
    name: "AddQuestion",
    components: {
        Multiselect,
        Form,
        Field,
        ErrorMessage
    },
    data() {
        return {
            id: this.$route.params.id,
            appLists: [],
            questiontype: "",
            question: {
            },
            optionLists: [],
            current_index:null,
            newOption:{},
            question_id:null,
        };
    },
    mounted() {
        if (this.id) {
            this.onUpdateDetails();
        }
        this.onGetAppSelectDD();
    },
    methods: {
        
        addMoreOption(){
            window.$("#addModal").modal("show");
        },
        editMoreOption(data,index){

            this.current_index = index;
            let parse_data = JSON.stringify(data)
            this.newOption = JSON.parse(parse_data);
            window.$("#addModal").modal("show");
        },
        onRemoveOption(index) {
            this.optionLists.splice(index, 1);
        },
        onClearForm(formRefName) {
            this.$refs[formRefName].resetForm();
        },
        closeAddEditModel() {
            window.$("#addModal").modal("hide");
            this.current_index = null;
            this.onClearForm("addEditOption");
        },
        updateOptionList(){
            var pare_data ;
            if(this.current_index != null){
                let obj  = {
                    option:this.newOption.option
                }
                 pare_data = JSON.stringify(obj);
                console.log("option list data",this.newOption);
                this.optionLists[this.current_index] = (JSON.parse(pare_data));
            }else{
                let obj = {
                    option:this.newOption.option
                }
                pare_data = JSON.stringify(obj);
                this.optionLists.push((JSON.parse(pare_data)));
            }
            this.closeAddEditModel();
            console.log("option list data",this.optionLists,pare_data);
        },

        updateStatus(e) {
            var status = e.target.checked;
            console.log(status);
            if (status == true) {
                this.users.status = "ACTIVE";
            }
            if (status == false) {
                this.users.status = "INACTIVE";
            }
            console.log(status, this.users.status);
        },
        onSave() {
            var obj = {
                question:this.question.questions,
                impact:this.question.impact,
                app_id:this.question.app_id,
                options:this.optionLists
            }
            console.log("final data",obj);
            var method = "POST";
            var url = "question";
            if (this.question_id != null) {
                method = "PUT";
                url = "question/" + this.question_id;
            }
            this.$api
                .webRequest({
                    _method: method,
                    _action: url,
                    _body: obj,
                    _hide_loader: true,
                    _buttonId: "save-btn",
                }).then((res) => {
                    this.$toast.success(res.message, {
                        position: "top-right"
                    });
                    this.$router.go(-1);
                    this.question_id = null;
                })
                .catch((e) => {
                    this.$toast.error(e.message, {
                        position: "top-right"
                    });
                });
        },
        onUpdateDetails() {
            this.$api
                .webRequest({
                    _method: "GET",
                    _action: "question/" + this.id,
                }).then((res) => {
                    this.question = res.info;
                    if (res.info.options) {
                        res.info.options.forEach((obj) => {
                            this.optionLists.push({ option: obj.option });
                        })
                    }
                    this.question_id = res.info.id;
                    this.onGetAppSelectDD();


                })
                .catch((e) => {
                    this.$toast.error(e.message, {
                        position: "top-right"
                    });
                });
        },

      

        updateLitStatus(e, item) {
            var obj = {};
            if (item.status == "ACTIVE") {
                obj.status = "INACTIVE";
            }
            if (item.status == "INACTIVE") {
                obj.status = "ACTIVE";
            }
            this.$api
                .webRequest({
                    _method: "PUT",
                    _action: "page/" + item.id + "/status",
                    _body: obj,
                }).then((res) => {
                    this.$toast.success(res.message, {
                        position: "top-right"
                    });
                    this.getList(1);
                    this.closeAddEditModel();
                })
                .catch((e) => {
                    this.$toast.error(e.message, {
                        position: "top-right"
                    });
                });
        },
        onGetAppSelectDD() {
            this.$api
                .webRequest({
                    _method: "GET",
                    _action: "select/apps",
                }).then((res) => {
                    this.appLists = res.list;
                })
                .catch((e) => {
                    this.$toast.error(e.message, {
                        position: "top-right"
                    });
                });
        }

    },
};
</script>
  