const messages = {
    en: {
        menus:{
            dashboard:"Dashboard",
            apps:"Apps",
            users:"Users",
            content_admin:"Content Admin",
            pages:"Pages",
            questionnaire:"Questionnaire",
            vitals:"Vitals",
            settings:"Settings",
            logout:"Logout"
        },
        pagination:{
            next:"Next",
            previous:"Previous",
            first:"First",
            last:"Last"
        },
        users: {
            users:"Users",
            add_user:"Add User",
            id:"ID",
            name:"Name",
            email:"Email",
            mobile_number:"Mobile",
            app:"App",
            created:"Created",
            expired:"Expired",
            action:"Action",
        },
        pages: {
            pages:"Pages",
            add_tc:"Add new T & C",
            id:"ID",
            name:"Name",
            url:"URL",
            location:"client {'|'} location",
            usage:"Usage",
            action:"Action",
        },
        apps:{
            app:"Apps",
            add_app:"Add App {'/'} Location ",
            id:"ID",
            name:"Name",
            scan:"Scan {'|'} Failed",
            type:"Type",
            installed:"Installed",
            location:"Location",
            action:"Action"
        },
        quetionnaries:{
            quetionnaries:"quetionnaire",
            add_question:"ADD QUESTIONNAIRE",
            add_option:"ADD OPTION",
            id:"ID",
            name:"Name",
            location:"client {'|'} location",
            usage:"Usage",
            action:"Action",
            option:"Option",
        }
    }
}
export default messages;