import {
  createStore
} from "vuex";
const store = createStore({
  state() {
    return {
      userInfo: {},
      isShowSidebar: false,
      summary: {
        company: [],
        businessGroup: [],
        webinars: [],
        activity: []
      },
      keyword: "",
      notificationCount: 0,
      title: "",
      branches: []
    };
  },
  mutations: {
    setUser(state, payload) {
      state.userInfo = payload;
    },
    setSummary(state, payload) {
      state.summary = payload;
    },
    showSidebar(state, payload) {
      state.isShowSidebar = payload;
    },
    keyword(state, payload) {
      state.keyword = payload;
    },
    setNotificationCount(state, payload) {
      state.notificationCount = payload;
    },
    setTitle(state, payload) {
      state.title = payload;
    },
    setBranches(state, payload) {
      state.branches = payload;
    }
  },
  getters: {
    getUser(state) {
      return state.userInfo;
    },
    getShowSidebar(state) {
      return state.isShowSidebar;
    },
    getSummary(state) {
      return state.summary;
    },
    getNotificationCount(state) {
      return state.notificationCount;
    },
    getTitle(state) {
      return state.title;
    },
    getBranches(state) {
      return state.branches;
    },
  },
  actions: {},
});

export default store;