<template>
    <div class="page-card">
        <div class="row align-items-center mb-3">
            <div class="col-md-6 d-flex align-items-center gap-4">
                <h2 class="page-title">{{ $t("pages.pages") }}</h2>
                <a href="javascript:void(0)" v-on:click="openAddEditModel()" class="add-link ms-4">{{ $t("pages.add_tc") }} <i
                        class="bi bi-plus-circle-fill"></i></a>
                <!-- <a href="javascript:void(0)" v-on:click="onCreateUser()" class="add-link ms-4" 
              >add user <i class="bi bi-plus-circle-fill"></i
            ></a> -->
            </div>
            <div class="col-md-6">
                <div class="d-flex justify-content-end font-12 text-uppercase gap-2 align-items-center">
                    <span>show</span>
                    <div class="bg-light-brand px-1">100</div>
                    <span>entries</span>
                </div>
            </div>
        </div>
        <div class="table-wrap">
            <table class="table table-style">
                <thead>
                    <tr>
                        <th scope="col"><img src="images/sort-icon.svg" class="sort-cion" @click="sorting($event,'id')">     {{ $t("pages.id") }}</th>
                        <th scope="col"><img src="images/sort-icon.svg" class="sort-cion" @click="sorting($event,'title')"> {{ $t("pages.name") }} </th>
                        <th scope="col" class="text-center"><img src="images/sort-icon.svg" class="sort-cion" @click="sorting($event,'url')"> {{ $t("pages.url") }}</th>
                        <th scope="col" class="text-center">{{ $t("pages.location") }}</th>
                        <th scope="col" class="text-center"><img src="images/sort-icon.svg" class="sort-cion" @click="sorting($event,'status')">{{ $t("pages.usage") }}</th>
                        <th scope="col" class="text-center">{{ $t("pages.action") }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="loader">
                        <td colspan="6" class="text-center">
                        <div class="spinner-border spinner-color" role="status">
                        </div>
                        </td>
                    </tr>
                    <tr v-else v-for="item in appList" :key="item.id">
                        <td>{{ item?.id }}</td>
                        <td> <a style="cursor:pointer">{{
                            item?.title }}</a></td>
                        <td><a href="javascript:void(0)" class="nav-link">{{ item?.url }}</a></td>
                        <td class="text-center" v-if="item?.location">{{item?.type   +" | " + item?.location }}</td>
                        <td class="text-center" v-else>-</td>
                        <td style="background-color:#2B8000" class="text-center" v-if="item?.status == 'ACTIVE'"
                            v-on:click="updateLitStatus($event, item)">In Use</td>
                        <td class="text-center" v-if="item?.status == 'INACTIVE'" v-on:click="updateLitStatus($event, item)">
                            Not Used</td>
                        <td>
                            <div class="action-ele">
                                <!-- <div class="form-check form-switch font-18">
                                    <input class="form-check-input" v-on:click="updateLitStatus($event, item.id)" :value="item.status" :checked="item.status == 'ACTIVE' ? true : false" type="checkbox" role="switch" id="flexSwitchCheckChecked">
                                </div> -->
                                <a href="javascript:void(0)" title="Edit" v-on:click="onUpdateDetails(item.id)"><img
                                        src="images/icon-edit.svg" width="22" alt=""></a>
                                <a href="javascript:void(0)" title="Delete"><img src="images/icon-archive.svg" title="Delete" width="18" alt=""></a>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <ErrorComponent @retry="getList(1)" ref="errorComponent" />
        </div>
        <div class="row">
            <div class="col-lg-12 mt-5 text-center">
                <div class="page-bottom-pagination text-right">
                    <Pagination @page-change="pageChange" @items-per-page-change="itemsPerPageChange"
                        ref="rolePagination" />
                </div>
            </div>
        </div>
    </div>
    <!-- add Modal -->
    <div class="modal fade" id="addModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
            <div class="modal-content">

                <div class="modal-header">
                    <h1 class="modal-title fs-5" v-if="page_id != null" id="exampleModalLabel">Edit Page </h1>
                    <h1 class="modal-title fs-5" v-else id="exampleModalLabel">Create Page</h1>
                    <button type="button" class="btn-close" v-on:click="closeAddEditModel()"></button>
                </div>
                <div class="modal-body form-style">
                    <Form @submit="onSave()" ref="addEditApp">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-floating mb-2">
                                    <Field type="text" class="form-control" name="name" v-model="pages.title"
                                        rules="required:first name" :validateOnInput="true" id="name"
                                        placeholder="First Name" />
                                    <label for="name">Name</label>
                                    <ErrorMessage name="name" class="validation-msg" />
                                </div>
                            </div>
                            <div class="col-md-12 pb-3">
                                
                                    <!-- <vue-editor
                                        v-bind="field"
                                        v-model="pages.content"
                                        name="description"
                                        ></vue-editor> -->
                                        <Editor
                                            v-bind="field"
                                            api-key="99hcvqa990t75hqybl4awsu02tzaah6t1fxudd3oo8v53zus"
                                            initial-value="Once upon a time..."
                                            v-model="pages.content"
                                            :init="{
                                                height: 500,    
                                                menubar: true,
                                                plugins: [
                                                'advlist autolink lists link image charmap print preview anchor',
                                                'searchreplace visualblocks code fullscreen',
                                                'insertdatetime media table paste code help wordcount'
                                                ],
                                                
                                            }"
                                        />
                                
                            </div>
                            <!-- <div class="col-md-12 pb-3">
                                <Field v-slot="{ app_id }" name="app_id" rules="required:app,true" :validateOnInput="true"
                                    v-model="pages.app_id">
                                    <Multiselect v-bind="app_id" ref="level_multiselect" mode="single" trackBy="id" label="name"
                                        valueProp="id" placeholder="Select Type" class="form-control" v-model="pages.app_id"
                                        :options="dropDownAppList" :createOption="true" />
                                </Field>
                                <ErrorMessage name="app_id" class="validation-msg" />
                            </div> -->
                            <div class="col-md-12">
                                <div class="form-floating mb-2">
                                    <Field type="text" class="form-control" name="code" v-model="pages.code"
                                        rules="required:client" :validateOnInput="true" id="code"
                                        placeholder="code" />
                                    <label for="name">Code</label>
                                    <ErrorMessage name="code" class="validation-msg" />
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-floating mb-2">
                                    <Field type="text" class="form-control" name="client" v-model="pages.type"
                                        rules="required:client" :validateOnInput="true" id="client"
                                        placeholder="client" />
                                    <label for="name">Client</label>
                                    <ErrorMessage name="client" class="validation-msg" />
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-floating mb-2">
                                    <Field type="text" class="form-control" name="location" v-model="pages.location"
                                        rules="required:location" :validateOnInput="true" id="name"
                                        placeholder="Location" />
                                    <label for="name">Location</label>
                                    <ErrorMessage name="location" class="validation-msg" />
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer border-0 justify-content-start">
                            <button id="save-btn" type="submit" class="btn btn-dark-brand px-4">Save</button>
                            <button type="button" v-on:click="closeAddEditModel()" class="btn btn-light-brand px-4"
                                data-bs-dismiss="modal">Close</button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
@import "quill/dist/quill.core.css";
@import "quill/dist/quill.snow.css";
@import "quill/dist/quill.bubble.css";
.quillWrapper .ql-snow.ql-toolbar {
  padding-top: 8px !important;
  padding-bottom: 4px !important;
  height: 50px !important;
}
.spinner-color{
  color:"#351D4E"
}
</style>    
<script>

// import { VueEditor } from "vue3-editor";
import Editor from '@tinymce/tinymce-vue'
// import Multiselect from "@vueform/multiselect";
// import "@vueform/multiselect/themes/default.css"
import Pagination from "@/components/Pagination";
import ErrorComponent from "@/components/ErrorComponent";
import '@vuepic/vue-datepicker/dist/main.css'
import {
    Form,
    Field,
    ErrorMessage
} from "vee-validate";
// import moment from 'moment';
import $ from "jquery";
export default {
    name: "ContentPages",
    components: { 
        Form,
        Field,
        ErrorMessage,
        ErrorComponent,
        Pagination,
        // VueEditor,
        // Multiselect
        Editor
    },
    data() {
        return {
            type: [{
                "name": "Android",
                "value": "ANDROID"
            },
            {
                "name": "Ios",
                "value": "IOS"
            },
            {
                "name": "PI-Internal",
                "value": "PI-INTERNAL"
            },
            {
                "name": "PI-External",
                "value": "PI-EXTERNAL"
            }
            ],
            pages: {},
            currentPage: 1,
            totalPage: 0,
            itemsPerPage: 10,
            page_id: null,
            filterObj: {
                keyword: "",
                offset: 1,
                sort_by: "created_at",
                sort_order: "desc",
                row_per_page: 10,
                level: "",
                status: ""
            },
            appList: [],
            dropDownAppList: [],
            loader:false,
        };
    },
    mounted() {
        // const phone_number = document.querySelector("#phone_number");
        // this.iti_1 = intlTelInput(phone_number, {
        //     formatOnDisplay: true,
        //     utilsScript: utilJs,
        // });
        this.getList(1);
    },
    methods: {
        onClearForm(formRefName) {
            this.$refs[formRefName].resetForm();
        },

        openAddEditModel() {
            window.$("#addModal").modal("show");
        },
        editAppDetails(id) {
            this.$router.push("/edit-app/" + id)
        },
        closeAddEditModel() {
            this.onClearForm("addEditApp");
            window.$("#addModal").modal("hide");
            this.page_id = null;
        },
        updateStatus(e) {
            var status = e.target.checked;
            console.log(status);
            if (status == true) {
                this.users.status = "ACTIVE";
            }
            if (status == false) {
                this.users.status = "INACTIVE";
            }
            console.log(status, this.users.status);
        },
        onSave() {
            var method = "POST";
            var url = "static-content";
            if (this.page_id != null) {
                method = "PUT";
                url = "static-content/" + this.page_id;
            }
            this.$api
                .webRequest({
                    _method: method,
                    _action: url,
                    _body: this.pages,
                    _hide_loader: true,
                    _buttonId: "save-btn",
                }).then((res) => {
                    this.$toast.success(res.message, {
                        position: "top-right"
                    });
                    this.getList(1);
                    this.closeAddEditModel();
                })
                .catch((e) => {
                    this.$toast.error(e.message, {
                        position: "top-right"
                    });
                });
        },
        onUpdateDetails(id) {
            this.$api
                .webRequest({
                    _method: "GET",
                    _action: "static-page/" + id,
                }).then((res) => {
                    this.pages = res.info;
                    this.pages.app_id =null;
                    this.page_id = res.info.id;
                    this.onGetAppSelectDD();

                    this.openAddEditModel();
                })
                .catch((e) => {
                    this.$toast.error(e.message, {
                        position: "top-right"
                    });
            });
        },
        pageChange(page) {
            this.getList(page);
        },
        itemsPerPageChange() {
            this.itemsPerPage = this.$refs.rolePagination.itemsPerPage;
            this.getList(1);
        },
        retry() {
            this.getList(1);
        },
        getList(page, sortBy, orderBy) {
            this.filterObj.offset = page - 1;
            this.currentPage = page;
            this.filterObj.row_per_page = this.itemsPerPage;

            if (sortBy) {
                this.filterObj.sort_by = sortBy;
            }
            if (orderBy) {
                this.filterObj.sort_order = orderBy;
            }

            this.loader = true;

            this.list = [];
            var method = "GET";
            this.$api
                .webRequest({
                    _method: method,
                    _action: "static-pages",
                    _body: this.filterObj,
                })
                .then((res) => {
                    this.appList = res.list;
                    this.loader = false;
                    // this.access = res.access;
                    if (page == 1) {
                        if (res.count != null && res.count > 0) {
                            if (this.$refs.rolePagination) {
                                this.$refs.rolePagination.setTotalCount(res.count);
                            }
                        } else {
                            if (this.$refs.rolePagination) {
                                this.$refs.rolePagination.setTotalCount(0);
                            }
                        }
                    }
                    if (!res.list || res.list.length <= 0) {
                        if (this.$refs.errorComponent) {
                            this.$refs.errorComponent.setTitle("Sorry, There is no record found.");
                        }
                    } else {
                        if (this.$refs.errorComponent) {
                            if (this.$refs.errorComponent.isShowMessage()) {
                                this.$refs.errorComponent.setTitle();
                            }
                        }
                    }
                    if (this.$refs.rolePagination) {
                        this.$refs.rolePagination.currentPage = this.currentPage;
                    }
                })
                .catch((e) => {
                    this.$toast.error(e.message, {
                        position: "top-right"
                    });
                    if (this.$refs.errorComponent) {
                        this.$refs.errorComponent.setTitle();
                    }
                });
        },
        sorting(e, sortBy) {
            var orderBy = "ASC";

            var className = "sorting_asc";
            var classes = e.target.className;
            if (classes.includes("sorting_asc")) {
                className = "sorting_desc";
            }

            $(".sorting_asc").removeClass("sorting_asc");
            $(".sorting_desc").removeClass("sorting_desc");

            if (className == "sorting_asc") {
                $(e.target).addClass("sorting_asc").removeClass("sorting_desc");
                orderBy = "ASC";
            } else if (className == "sorting_desc") {
                $(e.target).addClass("sorting_desc").removeClass("sorting_asc");
                orderBy = "DESC";
            }
            this.getList(1, sortBy, orderBy);
        },

        updateLitStatus(e, item) {
            var obj = {};
            if (item.status == "ACTIVE") {
                obj.status = "INACTIVE";
            }
            if (item.status == "INACTIVE") {
                obj.status = "ACTIVE";
            }
            this.$api
                .webRequest({
                    _method: "PUT",
                    _action: "page/" + item.id + "/status",
                    _body: obj,
                }).then((res) => {
                    this.$toast.success(res.message, {
                        position: "top-right"
                    });
                    this.getList(1);
                    this.closeAddEditModel();
                })
                .catch((e) => {
                    this.$toast.error(e.message, {
                        position: "top-right"
                    });
                });
        },
        onGetAppSelectDD() {
            this.$api
                .webRequest({
                    _method: "GET",
                    _action: "select/apps",
                }).then((res) => {
                    this.dropDownAppList = res.list;
                })
                .catch((e) => {
                    this.$toast.error(e.message, {
                        position: "top-right"
                    });
                });
        }

    },
};
</script>
    