<template>
    <div>
        <div class="container min-vh-100 d-flex justify-content-center align-items-center">
            <div class="login-wrap">
                <div class="row">
                    <div class="col-lg-6 bg-brand d-flex align-items-center justify-content-center">
                        <div class="text-center">
                            <img src="images/MVS-logo-white.svg" width="220" alt="Logo" class="mb-2">
                            <p class=" text-uppercase font-14 text-white" style="letter-spacing: 5px;">admin</p>
                        </div>
                    </div>
                    <div class="col-lg-6 bg-white py-5">
                        <div class="px-5">
                            <h4 class="font-medium text-brand mb-0 text-center">Welcome to MyVitalScan admin! </h4>
                            <p class="text-brand text-center mb-4">To start, please login.</p>
                            <Form @submit="onLogin" ref="loginForm">
                                <div class="form-floating mb-3">
                                    <Field type="email" class="form-control" name="email" v-model="email" rules="required:email|email"
                                        :validateOnInput="true" id="email" placeholder="name@example.com" />
                                    <label for="email">Email address  <span class="text-danger">*</span></label>
                                    <ErrorMessage name="email" class="validation-msg" />
                                </div>
                                <div class="form-floating mb-3">
                                    <Field :type="passwordFieldType" name="password" rules="required:password" id="password"
                                        :validateOnInput="true" v-model="password" class="form-control"
                                        placeholder="password" />
                                    <label for="password">Password <span class="text-danger">*</span></label>
                                    <ErrorMessage name="password" class="validation-msg" />
                                </div>
                                <div class="mb-3 form-check">
                                    <input type="checkbox" class="form-check-input" id="exampleCheck1">
                                    <label class="form-check-label" for="exampleCheck1">Stay logged in</label>
                                </div>
                                <button type="submit" id="save-btn" class="btn btn-login mb-3">Login</button>
                                <p class="font-14 text-center">Forgot Password? <a href="javascript:void(0)" @click="onForgetPassword" class="text-brand">Click here</a>
                                </p>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
    name: "PortalLogin",
    components: {
        Form,
        Field,
        ErrorMessage,
    },
    data() {
        return {
            email: "",
            password: "",
            passwordFieldType: "password",
            image: "/images/password.svg",
        };
    },
    mounted() {

    },
    methods: {
        onClearForm(formRefName) {
            this.$refs[formRefName].resetForm();
        },
        onLogin() {
            this.errorMessage = "";
            this.$api
                .getAccesstoken({
                    _action: "login",
                    _buttonId: "save-btn",
                    _body: { email: this.email, password: this.password },
                    _hide_loader: true,
                })
                .then((res) => {
                    if (res && res.access_token) {
                        localStorage.setItem("access_token", res.access_token);
                        this.$toast.success(res.message, {
                            position: 'top-right'
                        });
                        this.onGetMe();
                        this.onClearForm("loginForm");
                    }

                })
                .catch((e) => {
                    this.$toast.error(e.message, {
                        position: 'top-right'
                    });
                });
        },
        onSwitchVisibility() {
            this.passwordFieldType =
                this.passwordFieldType === "password" ? "text" : "password";
            this.image =
                this.image === "/images/password.svg" ? "/images/password.svg" : "/images/password.svg";
        },
        onForgetPassword() {
            this.$router.push("/forget-password");
        },
        onGetMe() {
            var method = "GET";
            this.$api
                .webRequest({
                    _method: method,
                    _action: "me",
                    _hide_loader: true,
                    _buttonId: "save-btn",
                }).then((res) => {
                    localStorage.setItem("my_user_id", res.info.id);
                    this.$router.push("/dashboard");
                })
                .catch((e) => {
                    this.$toast.error(e.message, { position: "top-right" });
                });
        },
    },
};
</script>

