import { createApp } from 'vue'
import App from './App.vue'
import router from "@/route/index";
import api from "./services/WebService";
import "./services/ValidationRules";
import "./langs/en/messages";
import sharedService from "./services/ShareService";
import { createI18n } from 'vue-i18n';
import helperService from "./services/HelperService"
import storeService from "./services/StoreService"
// import Notifications from "@kyvg/vue3-notification";
import messages from './langs/en/messages';
import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';

const app = createApp(App);

const i18n = createI18n({
    locale: 'en', // set locale
    //fallbackLocale: 'en',  set fallback locale
    messages,
  })

app.use(i18n);

app.config.globalProperties.$helperService = helperService;
app.config.globalProperties.$storeService = storeService;
app.config.globalProperties.$api = api;
app.config.globalProperties.$sharedService = sharedService;
app.use(ToastPlugin);
app.use(router);
// app.use(Notifications);
app.mount("#app");

import './assets/css/bootstrap.min.css'
import './assets/css/style.scss';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle'
import '../node_modules/bootstrap-icons/font/bootstrap-icons.min.css'
