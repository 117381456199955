<template>
    <div class="app-body row flex-nowrap">
      <Menu />
      <div class="content col py-3">
        <Header />
        <router-view></router-view>
      </div>
    </div>
  </template>
  <style>
  .sorting {
    white-space: nowrap !important;
  }
  </style>
  <script>
  import Header from "@/components/Header";
  import Menu from "@/components/Menu";
  
  export default {
    name: "MainPage",
    components: {
      Header,
      Menu
     
    },
  };
  </script>