<template>
  <div class="mt-3">
    <a href="javascipt:void(0)" class="text-secondary fw-light" v-on:click="$router.go(-1)"> &#60; Back </a>
  </div>
  <!-- <div class="row" v-if="loader">
   <div class="col-lg-12 d-flex justify-content-center" style="height: 400px !important;">
    <div class="spinner-border spinner-color" role="status">
              </div>
   </div>
  </div> -->
  <div class="row">
    <div class="col-lg-8">
      <div class="page-card">
        <div class="table-wrap">
          <table class="table table-style">
            <thead>
              <tr>
                <th scope="col"><img src="/images/sort-icon.svg" class="sort-cion">User Id</th>
                <th scope="col"><img src="/images/sort-icon.svg" class="sort-cion">User Name</th>
                <th scope="col"><img src="/images/sort-icon.svg" class="sort-cion">Scan</th>
                <th scope="col" class="text-center"><img src="/images/sort-icon.svg" class="sort-cion"> Error Code </th>
                <th scope="col" class="text-center"><img src="/images/sort-icon.svg" class="sort-cion"> Error Message
                </th>
                <th scope="col" class="text-center"><img src="/images/sort-icon.svg" class="sort-cion"> Date | Time </th>
                <th scope="col" class="text-center"><img src="/images/sort-icon.svg" class="sort-cion"> B2B Views </th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="loader">
                <td colspan="8" class="text-center">
                  <div class="spinner-border spinner-color" role="status">
                  </div>
                </td>
              </tr>
              <tr v-else v-for="item in list" :key="item.id">
                <td v-if="item?.user">{{ item?.user_id }}</td>
                <td v-else>{{ item?.id }}</td>
                <td v-if="item.user">{{ item?.user?.full_name }}</td>
                <td v-else>{{ item?.generate_code }}</td>
                <td class="text-center" v-if="item.scan_status == 'COMPLETE'"><span style="color: #2B8000;"> {{
                  $helperService.getTitleCaseFormate(item.scan_status) }}</span></td>
                <td class="text-center" v-if="item.scan_status == 'FAILED'"><span style="color: #F2233B;"> {{
                  $helperService.getTitleCaseFormate(item.scan_status) }}</span></td>
                <td class="text-center" v-if="item.scan_status == 'NOT_COMPLETE'"><span style="color: #F2233B;"> {{
                  $helperService.getTitleCaseFormate(item.scan_status) }}</span></td>
                <td class="text-center" v-if="item.scan_status == 'STOPPED'"><span style="color: #F2233B;"> {{
                  $helperService.getTitleCaseFormate(item.scan_status) }}</span></td>
                <td class="text-center" v-if="item.error_code">{{ item?.error_code }}</td>
                <td class="text-center" v-else>-</td>
                <td class="text-center" v-if="item.error_message">{{ item?.error_message }}</td>
                <td class="text-center" v-else>-</td>
                <td class="text-center">{{ $helperService.getDateTimeWithoutUtc(item.created_at) }}</td>
                <td class="text-center">-</td>

              </tr>
            </tbody>
          </table>
          <ErrorComponent @retry="getList(1)" ref="errorComponent" />
        </div>
        <div class="row">
          <div class="col-lg-12 mt-5 text-center">
            <div class="page-bottom-pagination text-right">
              <Pagination @page-change="pageChange" @items-per-page-change="itemsPerPageChange" ref="rolePagination" />
            </div>
          </div>
        </div>
      </div>
      <div class="page-card mb-3">
        <div class="row align-items-center mb-3 border-bottom">
          <div class="col-md-6 d-flex align-items-center gap-4">
            <h2 class="page-title">App activities</h2>
          </div>
          <div class="col-md-6 text-end">
            <select class="form-select border-0 w-auto ms-auto font-14 fw-light" v-on:change="onUserActivity(type)"
              v-model="type" aria-label="Default select example">
              <option selected value="WEEKLY">Last 7 Days (Weekly)</option>
              <option value="DAILY">Daily</option>
              <option value="MONTHLY">Monthly</option>
              <option value="YEARLY">Yearly</option>
            </select>
          </div>
        </div>
        <div class="card">
          <div class="card-header" v-if="type == 'WEEKLY'">
            <VueDatePicker class="form-control" @closed="onUserActivity('WEEKLY')" v-model="week" week-picker
              :enable-time-picker="false"></VueDatePicker>
          </div>
          <div class="card-header" v-if="type == 'MONTHLY'">
            <VueDatePicker class="form-control" month-picker @closed="onUserActivity('MONTHLY')" v-model="month"
              :enable-time-picker="false"></VueDatePicker>
          </div>
          <div class="card-header" v-if="type == 'YEARLY'">
            <VueDatePicker class="form-control" year-picker @closed="onUserActivity('YEARLY')" v-model="year"
              :enable-time-picker="false"></VueDatePicker>
          </div>
          <div class="card-header" v-if="type == 'DAILY'">
            <VueDatePicker class="form-control" v-model="today_date" @closed="onUserActivity('DAILY')"
              :enable-time-picker="false">
            </VueDatePicker>
          </div>
          <div class="card-body" id="graph-container">
            <canvas id="userActivity" class="chartjs-render-monitor"></canvas>
          </div>
        </div>
        <div class="d-flex flex-row pt-3">
          <div class="form-check mx-2">
            <input class="form-check-input" type="radio" name="complete" id="complete1" style="background-color: #2B8000;"
              checked>
            <label class="form-check-label" for="complete1">
              Completed
            </label>
          </div>
          <div class="form-check mx-2">
            <input class="form-check-input" type="radio" name="failed" style="background-color: #F2233B;" id="failed1"
              checked>
            <label class="form-check-label" for="failed1">
              Failed
            </label>
          </div>
          <div class="form-check mx-2">
            <input class="form-check-input" type="radio" name="b2b" id="b2b1">
            <label class="form-check-label" for="b2b1">
              B2B Views
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="page-card">
        <div class="text-center mt-3 pb-3 mb-3 border-bottom">
          <img src="/images/placeholder.png" width="150" height="150" class=" object-fit-cover rounded-circle mb-3"
            alt="">
          <p class=" font-24 fw-bold mb-1" v-if="apps?.name">{{ apps?.name }}</p>
          <p class=" font-24 fw-bold mb-1" v-else> - </p>
          <p class=" text-secondary" v-if="apps?.type">{{ $helperService.getTitleCaseFormate(apps?.type) }}</p>
          <br>
          <button class="btn btn-dark-brand px-5 mb-3" v-on:click="openAddEditModel()">Edit App Info</button>
        </div>
        <table class=" table table-borderless font-14 mb-lg-5">
          <tr>
            <td class="text-secondary">Client</td>
            <td class="text-end" v-if="apps?.name">{{ apps?.name }}</td>
            <td class="text-end" v-else>-</td>
          </tr>
          <tr>
            <td class="text-secondary">Location</td>
            <td class="text-end" v-if="apps?.location">{{ apps?.location }}</td>
            <td class="text-end" v-else>-</td>
          </tr>
          <tr>
            <td class="text-secondary">App Intalled</td>
            <td class="text-end" v-if="apps?.created_at">{{
              $helperService.getFormatedDateWithoutTime(apps?.created_at) }}</td>
            <td class="text-end" v-else>-</td>
          </tr>
          <tr>
            <td class="text-secondary">Questionnarie </td>
            <!-- <td class="text-end" v-if="apps?.created_at">{{ $helperService.getFormatedDateAndTime(apps?.created_at) }}</td>
            <td class="text-end" v-else>-</td> -->
            <td class="text-end">-</td>

          </tr>
          <tr>
            <td class="text-secondary">Status</td>
            <td class="text-end" v-if="apps?.status">{{ $helperService.getTitleCase(apps?.status) }}</td>
            <td class="text-end" v-else>-</td>
          </tr>
        </table>

        <p class=" text-secondary mb-2">Note</p>
        <p v-if="apps?.note">{{ apps?.notes }}</p>
        <p v-else>-</p>
      </div>
    </div>
  </div>
  <div class="modal fade" id="addModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
      <div class="modal-content">

        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">Edit App Instance</h1>
          <button type="button" class="btn-close" v-on:click="closeAddEditModel()"></button>
        </div>
        <div class="modal-body form-style">
          <Form @submit="onSave()" ref="addEditApp">
            <div class="row">
              <div class="col-md-2" v-if="app_id != null">
                <p class=" text-secondary font-14">APP ID <br>
                  <span class="font-medium">{{ apps?.id }}</span>
                </p>
              </div>
              <div class="col-md-10">
                <div class="form-floating mb-2">
                  <Field type="text" class="form-control" name="name" v-model="apps.name" rules="required:name"
                    :validateOnInput="true" id="name" placeholder="Name" />
                  <label for="name">App Name</label>
                  <ErrorMessage name="name" class="validation-msg" />
                </div>
                <div class="form-floating mb-2">
                  <Field type="text" class="form-control" name="client" v-model="apps.client" rules="required:client"
                    :validateOnInput="true" id="client" placeholder="Client" />
                  <label for="client">Client</label>
                  <ErrorMessage name="client" class="validation-msg" />
                </div>
                <div class="form-floating mb-2">
                  <Field type="text" class="form-control" name="location" v-model="apps.location"
                    rules="required:location" :validateOnInput="true" id="location" placeholder="Location" />
                  <label for="location">Location</label>
                  <ErrorMessage name="location" class="validation-msg" />
                </div>

                <div class="input-group mb-2">
                  <span class="input-group-text" id="inputGroup-sizing-sm"><img src="/images/email-icon.svg" width="18"
                      alt=""></span>
                  <div class="form-floating">
                    <Field type="email" class="form-control" name="email" v-model="apps.email" id="email"
                      placeholder="Contact email address (optional)" />
                    <label for="email"> Email</label>
                  </div>
                </div>
                <div class="input-group mb-2">
                  <span class="input-group-text" id="inputGroup-sizing-sm"><img src="/images/call-icon.svg" width="18"
                      alt=""></span>
                  <div class="form-floating">
                    <Field type="text" class="form-control" name="phone_number" v-model="apps.phone_number"
                      id="phone_number" placeholder="Contact phone number (optional)" />
                    <label for="phone_number">Phone Number</label>
                  </div>
                </div>
              </div>
            </div>
            <hr class=" border-secondary-subtle">
            <div class="row">
              <div class="col-md-4">
                <Field v-slot="{ field }" name="aap" rules="required:app type,true" :validateOnInput="true"
                  v-model="apps.type">
                  <Multiselect v-bind="field" ref="level_multiselect" mode="single" trackBy="value" label="name"
                    valueProp="value" placeholder="Select App Type" class="form-control border-radius" :disabled="has_disable == 'ANDROID_PHONE' || has_disable == 'IOS_PHONE'" v-model="apps.type"
                    :options="options" :searchable="true" :createOption="true" />
                </Field>
                <ErrorMessage name="aap" class="validation-msg" />
              </div>
              <div class="col-md-8">
                <div class="d-flex align-items-center gap-3 mb-3">
                  <label for="" class="font-18 text-secondary mb-0">Status</label>
                  <label class="font-14">Not active</label>
                  <div class="form-check form-switch">
                    <input class="form-check-input" v-on:click="updateStatus($event)" type="checkbox"
                      :checked="apps.status == 'ACTIVE' ? true : false" role="switch" id="flexSwitchCheckDefault">
                    <label class="form-check-label" for="flexSwitchCheckDefault">Active</label>
                  </div>
                </div>
                <div v-if="app_id != null">
                  <label for="phone_number">Created At</label>
                  <div class="input-group flex-nowrap mb-3 input-group-lg">
                    <span class="input-group-text " id="addon-wrapping"><img src="/images/calendar-icon.svg" width="18"
                        alt=""></span>
                    <VueDatePicker class="form-control" v-model="apps.created_at" :enable-time-picker="false">
                    </VueDatePicker>
                  </div>
                  <label for="phone_number">Expired At</label>
                  <div class="input-group flex-nowrap mb-3 input-group-lg">
                    <span class="input-group-text bg-warning" id="addon-wrapping"><img src="/images/calendar-icon.svg"
                        width="18" alt=""></span>
                    <VueDatePicker class="form-control" v-model="apps.expired_at" :enable-time-picker="false">
                    </VueDatePicker>
                  </div>
                </div>
              </div>
            </div>
            <hr class=" border-secondary-subtle">
            <div class="form-floating mb-2">
              <textarea v-model="apps.notes" class="form-control" rows="5" cols="6" id="Note"
                placeholder="Note"></textarea>
              <label for="Note">Note</label>
            </div>
            <div class="modal-footer border-0 justify-content-start">
              <button id="save-btn" type="submit" class="btn btn-dark-brand px-4">Save</button>
              <button type="button" v-on:click="closeAddEditModel()" class="btn btn-light-brand px-4"
                data-bs-dismiss="modal">Close</button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.spinner-color {
  color: "#351D4E"
}
</style>
<script>
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css"
import Pagination from "@/components/Pagination";
import ErrorComponent from "@/components/ErrorComponent";
import Chart from 'chart.js/auto';
Chart.register("line");
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import {
  Form,
  Field,
  ErrorMessage
} from "vee-validate";
import { ref } from 'vue';
import moment from 'moment';
export default {
  name: "UserProfile",
  components: {
    Form,
    Field,
    ErrorMessage,
    VueDatePicker,
    ErrorComponent,
    Pagination,
    Multiselect
  },
  data() {
    return {
      id: this.$route.params.id,
      total_count: null,
      total_title: null,
      userActivity: "userActivity",
      type: "WEEKLY",
      week: [],
      year: ref(new Date().getFullYear()),
      today_date: ref(new Date()),
      month: ref({
        month: new Date().getMonth(),
        year: new Date().getFullYear()
      }),
      userChart: "",
      filterObj: {
        from_date: "",
        to_date: "",
        app_id: this.$route.params.id,
        graph_type: "FAILED"
      },
      disable: true,
      complete_left_title: [],
      failed_left_title: [],
      common_bottom_title: [],
      complete_scan: "",
      failed_result: "",
      shared_result: 0,
      apps: { name: "", client: "", location: "", email: "", phone_number: "", type: "MOBILE" },
      loader: false,
      currentPage: 1,
      disbale: true,
      totalPage: 0,
      itemsPerPage: 10,
      user_id: null,
      tableObj: {
        keyword: "",
        offset: "",
        sort_by: "created_at",
        sort_order: "desc",
        row_per_page: 10,
        level: "",
        status: ""
      },
      list: [],
      app_id: null,
      has_disable:"",
      options: [{
        "name": "Android Phone",
        "value": "ANDROID_PHONE"
      },
      {
        "name": "Android Internal",
        "value": "ANDROID_INTERNAL"
      },
      {
        "name": "Android External",
        "value": "ANDROID_EXTERNAL"
      },
      {
        "name": "iPhone",
        "value": "IOS_PHONE"
      },
      {
        "name": "Pi Kiosk 1",
        "value": "KIOSK_PI_INTERNAL"
      },
      {
        "name": "Pi Kiosk 2",
        "value": "KIOSK_PI_EXTERNAL"
      }
      ],
    };
  },
  mounted() {

    let date_today = new Date();

    let first_day_of_the_week = new Date(date_today.setDate(date_today.getDate()
      - date_today.getDay()));

    let last_day_of_the_week = new Date(date_today.setDate(date_today.getDate()
      - date_today.getDay() + 6));

    this.week.push(first_day_of_the_week, last_day_of_the_week);
    this.filterObj.from_date = first_day_of_the_week;
    this.filterObj.to_date = last_day_of_the_week;
    this.onUserActivity(this.type);
    this.onAppsInfo();
    this.getList(1)
  },
  methods: {

    onClearForm(formRefName) {
      this.$refs[formRefName].resetForm();
    },
    openAddEditModel() {
      window.$("#addModal").modal("show");
    },

    closeAddEditModel() {
      window.$("#addModal").modal("hide");
    },
    clearfilterObj() {
      this.filterObj.from_date = "";
      this.filterObj.to_date = "";
    },
    onUserActivity(type) {
      this.type = type;
      if (this.type == "WEEKLY") {
        this.filterObj.from_date = moment(String(this.week[0])).format(
          "Y-MM-DD"
        );
        this.filterObj.to_date = moment(String(this.week[1])).format(
          "Y-MM-DD"
        );

        console.log(this.filterObj);
      }
      if (this.type == "DAILY") {
        this.filterObj.from_date = moment(String(this.today_date)).format(
          "Y-MM-DD"
        );
        this.filterObj.to_date = moment(String(this.today_date)).format(
          "Y-MM-DD"
        );
        console.log(this.filterObj);
      }

      if (this.type == "MONTHLY") {
        let month = this.getNumberOfMonths(this.month.month);
        console.log("from Monthly :", month);
        let year = this.month.year;
        let date = new Date(year, month, 1), y = date.getFullYear(), m = date.getMonth();
        let firstDay = new Date(y, m, 1);
        let lastDay = new Date(y, m + 1, 0);
        console.log(firstDay, lastDay);
        this.filterObj.from_date = moment(firstDay).format("Y-MM-DD");
        this.filterObj.to_date = moment(lastDay).format("Y-MM-DD");
        console.log(this.filterObj, this.month);

      }

      if (this.type == "YEARLY") {

        this.filterObj.year = moment(String(this.year)).format("Y");
        const firstDay = new Date(this.filterObj.year, 0, 1);
        const lastDay = new Date(this.filterObj.year, 11, 31);
        this.filterObj.from_date = moment(firstDay).format("Y-MM-DD");
        this.filterObj.to_date = moment(lastDay).format("Y-MM-DD");
        console.log(this.filterObj, this.year);

      }
      window.$("#userActivity").remove();
      window.$('#graph-container').append('<canvas id="userActivity" class="chartjs-render-monitor"><canvas>');
      this.loader = true;
      this.$api
        .webRequest({
          _method: "GET",
          _action: "app/" + this.type + "/graph",
          _body: this.filterObj
        })
        .then(res => {
          this.loader = false;
          if (res.complete_scan && res.failed_scan) {
            this.failed_result = res.failed_count;
            this.complete_result = res.complete_count;
            this.shared_result = res.shared_count;
            var obj = {
              failed_scan_title: res.failed_scan_title,
              failed_color_code: res.failed_color_code,
              success_color_code: res.success_color_code,
              success_scan_title: res.success_scan_title,
            }
            this.userActivityChart(res.complete_scan, res.failed_scan, obj);
          } else {
            var temObj = {
              failed_scan_title: "Failed Scan",
              failed_color_code: "#F2233B",
              success_color_code: "Complete Scan",
              success_scan_title: "#2B8000",
            }
            var complete_scan = [];
            var failed_scan = [];
            this.userActivityChart(complete_scan, failed_scan, temObj);
          }
          this.clearfilterObj();
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
    },
    userActivityChart(complete_scan, failed_scan, obj) {

      this.complete_left_title = [];
      this.failed_left_title = [];
      this.common_bottom_title = [];

      for (var i = 0; i < complete_scan.length; i++) {
        if (complete_scan[i].complete) {
          this.complete_left_title.push(complete_scan[i].complete);
        }
        this.common_bottom_title.push(complete_scan[i].graph_date);
      }
      for (var j = 0; j < failed_scan.length; j++) {
        if (failed_scan[j].failed) {
          this.failed_left_title.push(failed_scan[j].failed);
        }
      }

      console.log("title from pie :", this.bottom_title);
      if (document.getElementById(this.userActivity)) {
        var ctx = document.getElementById(this.userActivity).getContext("2d");
        // var $this = this;
        this.userChart = new Chart(ctx, {
          type: "line",
          data: {
            datasets: [
              {
                label: obj.success_scan_title,
                data: this.complete_left_title,
                fill: false,
                backgroundColor: [obj.success_color_code],
                borderColor: obj.success_color_code,
                hoverOffset: 4,
                tension: 0.1
              }, {
                label: obj.failed_scan_title,
                data: this.failed_left_title,
                fill: false,
                backgroundColor: [obj.failed_color_code],
                borderColor: obj.failed_color_code,
                hoverOffset: 4,
                tension: 0.1,
                type: "line"
              }
            ],
            labels: this.common_bottom_title
          },
          options: {
            tooltips: {
              callbacks: {
                title: function (t, d) {
                  let title = d.datasets[t[0].datasetIndex].label;
                  return title;
                },
                label: function (t, d) {
                  // let title = d.datasets[t.datasetIndex].label;
                  let label = d.labels[t.index];
                  let value = d.datasets[t.datasetIndex].data[t.index];
                  return label + ": " + value + "%";
                }
              }
            }
          }
        });
      }
    },
    onAppsInfo() {
      var method = "GET";
      this.$api
        .webRequest({
          _method: method,
          _action: "app/" + this.id,
          _hide_loader: true,
        }).then((res) => {
          this.apps = res.info;
          this.app_id = res.info?.id;
          this.has_disable = res?.info?.type;
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
    },

    getNumberOfMonths(value) {
      return value;
    },
    pageChange(page) {
      this.getList(page);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.rolePagination.itemsPerPage;
      this.getList(1);
    },
    retry() {
      this.getList(1);
    },
    getList(page, sortBy, orderBy) {
      this.tableObj.offset = page - 1;
      this.currentPage = page;
      this.tableObj.row_per_page = this.itemsPerPage;

      if (sortBy) {
        this.tableObj.sort_by = sortBy;
      }
      if (orderBy) {
        this.tableObj.sort_order = orderBy;
      }
      this.loader = true;
      this.list = [];
      var method = "GET";
      this.$api
        .webRequest({
          _method: method,
          _action: "apps/" + this.id,
          _body: this.tableObj,
        })
        .then((res) => {
          this.list = res.list;
          this.loader = false;
          // this.access = res.access;
          if (page == 1) {
            if (res.count != null && res.count > 0) {
              if (this.$refs.rolePagination) {
                this.$refs.rolePagination.setTotalCount(res.count);
              }
            } else {
              if (this.$refs.rolePagination) {
                this.$refs.rolePagination.setTotalCount(0);
              }
            }
          }
          if (!res.list || res.list.length <= 0) {
            if (this.$refs.errorComponent) {
              this.$refs.errorComponent.setTitle("Sorry, There is no record found.");
            }
          } else {
            if (this.$refs.errorComponent) {
              if (this.$refs.errorComponent.isShowMessage()) {
                this.$refs.errorComponent.setTitle();
              }
            }
          }
          if (this.$refs.rolePagination) {
            this.$refs.rolePagination.currentPage = this.currentPage;
          }
        })
        .catch((e) => {
          this.loader = false;
          this.$toast.error(e.message, { position: "top-right" });
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setTitle();
          }
        });
    },
    onSave() {

      if (this.apps.expired_at) {
        this.apps.expired_at = moment(this.apps.expired_at).format(
          "yyyy-MM-DD"
        );
      }
      var method = "POST";
      var url = "app";
      if (this.app_id != null) {
        method = "PUT";
        url = "app/" + this.app_id;
      }
      this.$api
        .webRequest({
          _method: method,
          _action: url,
          _body: this.apps,
          _hide_loader: true,
          _buttonId: "save-btn",
        }).then((res) => {
          this.$toast.success(res.message, {
            position: "top-right"
          });
          this.closeAddEditModel();
          this.onClearForm("addEditApp");
          this.onAppsInfo();
        })
        .catch((e) => {
          this.$toast.error(e.message, {
            position: "top-right"
          });
        });
    },
  },
};
</script>
  