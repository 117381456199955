<template>
    <div class="page-card">
        <div class="row align-items-center mb-3">
            <div class="col-md-6 d-flex align-items-center gap-4">
                <h2 class="page-title">{{ $t("apps.app") }}</h2>
                <a href="javascript:void(0)" v-on:click="openAddEditModel()" class="add-link ms-4">{{ $t("apps.add_app") }}
                    <i class="bi bi-plus-circle-fill"></i></a>
                <!-- <a href="javascript:void(0)" v-on:click="onCreateUser()" class="add-link ms-4" 
          >add user <i class="bi bi-plus-circle-fill"></i
        ></a> -->
            </div>
            <div class="col-md-6">
                <div class="d-flex justify-content-end font-12 text-uppercase gap-2 align-items-center">
                    <span>show</span>
                    <div class="bg-light-brand px-1">100</div>
                    <span>entries</span>
                </div>
            </div>
        </div>
        <div class="row align-items-center pt-1 pb-2">
          <div class="col-lg-4">
            <div class="input-group flex-nowrap header-seach">
              <span class="input-group-text" id="addon-wrapping"><i class="bi bi-search"></i></span>
              <input type="text" 
                v-model="filterObj.keyword"
                v-on:blur="getList(1)"
                v-on:keyup.enter="$event.target.blur()"
              class="form-control" placeholder="Search">
            </div>
          </div>
        </div>
        <div class="table-wrap">
            <table class="table table-style">
                <thead>
                    <tr>
                        <th scope="col"><img src="images/sort-icon.svg" class="sort-cion" @click="sorting($event,'id')"> {{ $t("apps.id") }}</th>
                        <th scope="col"><img src="images/sort-icon.svg" class="sort-cion"  @click="sorting($event,'name')"> {{ $t("apps.name") }} </th>
                        <th scope="col" class="text-center"><img src="images/sort-icon.svg" @click="sorting($event,'type')" class="sort-cion"> {{
                            $t("apps.scan") }} </th>
                        <th scope="col" class="text-center">{{
                            $t("apps.type") }} </th>
                        <th scope="col" class="text-center"><img src="images/sort-icon.svg" @click="sorting($event,'created_at')" class="sort-cion"> {{
                            $t("apps.installed") }} </th>
                        <th scope="col" class="text-center">{{
                            $t("apps.location") }} </th>
                        <th scope="col" class="text-center">{{ $t("apps.action") }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="loader">
                        <td colspan="7" class="text-center">
                            <div class="spinner-border spinner-color" role="status">
                            </div>
                        </td>
                    </tr>
                    <tr v-else v-for="item in appList" :key="item.id">
                        <td>{{ item?.id }}</td>
                        <td> <a style="text-decoration:underline;cursor:pointer" v-on:click="editAppDetails(item.id)">{{
                            item?.name }}</a></td>
                        <td>
                            <h6 class="text-center"><span class="text-success">{{ item?.all }}</span> | <span
                                    class="text-danger">{{ item?.failed }}</span></h6>
                        </td>
                        <td>
                            <h6 class="text-center">{{ $helperService.getTitleCaseFormate(item?.type) }}</h6>
                        </td>
                        <td class="text-center">{{ $helperService.getFormatedDateAndTime(item.created_at) }}</td>
                        <td>{{ item?.location }}</td>

                        <td>
                            <div class="action-ele">
                                <div class="form-check form-switch font-18">
                                    <input class="form-check-input" v-on:click="updateLitStatus($event, item.id)"
                                        :value="item.status" :checked="item.status == 'ACTIVE' ? true : false"
                                        type="checkbox" role="switch" id="flexSwitchCheckChecked">
                                </div>
                                <a href="javascript:void(0)" title="Edit" v-on:click="onUpdateDetails(item.id)"><img
                                        src="images/icon-edit.svg" width="22" alt=""></a>
                                <a href="javascript:void(0)" title="Delete"><img src="images/icon-archive.svg" width="18" alt=""></a>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <ErrorComponent @retry="getList(1)" ref="errorComponent" />
        </div>
        <div class="row">
            <div class="col-lg-12 mt-5 text-center">
                <div class="page-bottom-pagination text-right">
                    <Pagination @page-change="pageChange" @items-per-page-change="itemsPerPageChange"
                        ref="rolePagination" />
                </div>
            </div>
        </div>
    </div>
    <!-- add Modal -->
    <!-- <div class="modal fade" id="addModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
        <div class="modal-content">

            <div class="modal-header">
                <h1 class="modal-title fs-5" v-if="app_id != null" id="exampleModalLabel">Edit App </h1>
                <h1 class="modal-title fs-5" v-else id="exampleModalLabel">Create App</h1>
                <button type="button" class="btn-close" v-on:click="closeAddEditModel()"></button>
            </div>
            <div class="modal-body form-style">
                <Form @submit="onSave()" ref="addEditApp">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-floating mb-2">
                                <Field type="text" class="form-control" name="name" v-model="apps.name" rules="required:first name" :validateOnInput="true" id="name" placeholder="First Name" />
                                <label for="name">Name</label>
                                <ErrorMessage name="name" class="validation-msg" />
                            </div>
                        </div>
                        
                        <div class="col-md-12 pb-3">
                          <Field v-slot="{ field }" name="level" rules="required:level,true"
                              :validateOnInput="true" v-model="apps.type">
                              <Multiselect v-bind="field" ref="level_multiselect" mode="single"
                                  trackBy="value" label="name" valueProp="value" placeholder="Select Type"
                                  class="form-control" v-model="apps.type" :options="type"
                                  :searchable="true" :createOption="true" />
                          </Field>
                          <ErrorMessage name="level" class="validation-msg" />
                        </div>
                        <div class="col-md-12">
                            <div class="form-floating mb-2">
                                <Field type="text" class="form-control" name="location" v-model="apps.location" rules="required:location" :validateOnInput="true" id="location" placeholder="Last Name" />
                                <label for="location">Location</label>
                            </div>
                            <ErrorMessage name="location" class="validation-msg" />
                        </div>
                    </div>
                    <div class="modal-footer border-0 justify-content-start">
                        <button id="save-btn" type="submit" class="btn btn-dark-brand px-4">Save</button>
                        <button type="button" v-on:click="closeAddEditModel()" class="btn btn-light-brand px-4" data-bs-dismiss="modal">Close</button>
                    </div>
                </Form>
            </div>
        </div>
    </div>
</div> -->
    <div class="modal fade" id="addModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
            <div class="modal-content">

                <div class="modal-header">
                    <h1 class="modal-title fs-5" v-if="app_id != null" id="exampleModalLabel">Edit App Instance</h1>
                    <h1 class="modal-title fs-5" v-else id="exampleModalLabel">Add App Instance</h1>
                    <button type="button" class="btn-close" v-on:click="closeAddEditModel()"></button>
                </div>
                <div class="modal-body form-style">
                    <Form @submit="onSave()" ref="addEditApp">
                        <div class="row">
                            <div class="col-md-2" v-if="app_id">
                                <p class=" text-secondary font-14">APP ID <br>
                                    <span class="font-medium">{{ apps.id }}</span>
                                </p>
                            </div>
                            <div class="col-md-10">
                                <div class="form-floating mb-2">
                                    <Field type="text" class="form-control border-radius" name="name" v-model="apps.name"
                                        rules="required:name" :validateOnInput="true" id="name" placeholder="Name" />
                                    <label for="name">App Name</label>
                                    <ErrorMessage name="name" class="validation-msg" />
                                </div>
                                <div class="form-floating mb-2">
                                    <Field type="text" class="form-control border-radius" name="client" v-model="apps.client"
                                        rules="required:client" :validateOnInput="true" id="client" placeholder="Client" />
                                    <label for="client">Client</label>
                                    <ErrorMessage name="client" class="validation-msg" />
                                </div>
                                <div class="form-floating mb-2">
                                    <Field type="text" class="form-control border-radius" name="location" v-model="apps.location"
                                        rules="required:location" :validateOnInput="true" id="location"
                                        placeholder="Location" />
                                    <label for="location">Location</label>
                                    <ErrorMessage name="location" class="validation-msg" />
                                </div>


                                <div class="input-group mb-2">
                                    <span class="input-group-text border-radius" id="inputGroup-sizing-sm"><img
                                            src="images/email-icon.svg" class="border-radius" width="18" alt=""></span>
                                    <div class="form-floating">
                                        <Field type="email" class="form-control border-radius" name="email" v-model="apps.email"
                                            rules="required:email|email" :validateOnInput="true" id="email"
                                            placeholder="Email" />
                                        <label for="email"> Email</label>
                                    </div>
                                </div>
                                <ErrorMessage name="email" class="validation-msg" />
                                <div class="input-group mb-2">
                                    <span class="input-group-text border-radius" id="inputGroup-sizing-sm"><img src="images/call-icon.svg"
                                            width="18" alt=""></span>
                                    <div class="form-floating">
                                        <Field type="text" class="form-control border-radius" name="phone_number"
                                            v-model="apps.phone_number" rules="required:phone number"
                                            :validateOnInput="true" id="phone_number" placeholder="Phone Number" />
                                        <label for="phone_number">Phone Number</label>
                                    </div>
                                </div>
                                <ErrorMessage name="phone_number" class="validation-msg" />
                            </div>
                        </div>
                        <hr class=" border-secondary-subtle">
                        <div class="row">
                            <div class="col-md-4">
                                <Field v-slot="{ field }" name="level" rules="required:level,true"
                              :validateOnInput="true" v-model="apps.type">
                              <Multiselect v-bind="field" ref="level_multiselect" mode="single" :disabled="has_disable == 'ANDROID_PHONE' || has_disable == 'IOS_PHONE'"
                                  trackBy="value" label="name" valueProp="value" placeholder="Select App Type"
                                  class="form-control border-radius" v-model="apps.type" :options="options"
                                  :searchable="true" :createOption="true" />
                          </Field>
                          <ErrorMessage name="level" class="validation-msg" />
                            </div>
                            <div class="col-md-8">
                                <div class="d-flex align-items-center gap-3 mb-3">
                                    <label for="" class="font-18 text-secondary mb-0">Status</label>
                                    <label class="font-14">Not active</label>
                                    <div class="form-check form-switch">
                                        <input class="form-check-input" v-on:click="updateStatus($event)" type="checkbox"
                                            :checked="apps.status == 'ACTIVE' ? true : false" role="switch"
                                            id="flexSwitchCheckDefault">
                                        <label class="form-check-label" for="flexSwitchCheckDefault">Active</label>
                                    </div>
                                </div>
                                <div v-if="app_id != null">
                                    <label for="created_at">Created At</label>
                                    <div class="input-group flex-nowrap mb-3 input-group-lg">
                                        <span class="input-group-text border-radius" id="addon-wrapping"><img
                                                src="images/calendar-icon.svg" width="18" alt=""></span>
                                        <VueDatePicker class="form-control border-radius" id="created_at" v-model="apps.created_at"
                                            :enable-time-picker="false">
                                        </VueDatePicker>
                                    </div>
                                    <label for="expried_at">Expired At</label>
                                    <div class="input-group flex-nowrap mb-3 input-group-lg">
                                        <span class="input-group-text bg-warning border-radius" id="addon-wrapping"><img
                                                src="images/calendar-icon.svg" width="18" alt=""></span>
                                        <VueDatePicker class="form-control border-radius" id="expired_at" v-model="apps.expired_at"
                                            :enable-time-picker="false">
                                        </VueDatePicker>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr class=" border-secondary-subtle">
                        <div class="form-floating mb-2">
                            <textarea v-model="apps.notes" class="form-control" rows="5" cols="6" id="Note"
                                placeholder="Note"></textarea>
                            <label for="Note">Note</label>
                        </div>
                        <div class="modal-footer border-0 justify-content-start">
                            <button id="save-btn" type="submit" class="btn btn-dark-brand px-4">Save</button>
                            <button type="button" v-on:click="closeAddEditModel()" class="btn btn-light-brand px-4"
                                data-bs-dismiss="modal">Close</button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
.spinner-color {
    color: "#351D4E"
}
.multiselect-caret{
    background-color: #4B286D !important
}

.multiselcet.is-active{
    box-shadow :  #4B286D !important;
    border: 1px #4B286D !important
}

</style>
<script>
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css"
import Pagination from "@/components/Pagination";
import ErrorComponent from "@/components/ErrorComponent";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import {
    Form,
    Field,
    ErrorMessage
} from "vee-validate";
import moment from 'moment';
import $ from "jquery";
export default {
    name: "UsersList",
    components: {
        Form,
        Field,
        ErrorMessage,
        ErrorComponent,
        Pagination,
        VueDatePicker,
        Multiselect
    },
    data() {
        return {
            options: [{
                "name": "Android Phone",
                "value": "ANDROID_PHONE"
            },
            {
                "name": "iPhone",
                "value": "IOS_PHONE"
            },
            {
                "name": "Pi Kiosk 1",
                "value": "KIOSK_PI_INTERNAL"
            },
            {
                "name": "Pi Kiosk 2",
                "value": "KIOSK_PI_EXTERNAL"
            },
            {
                "name": "Android Internal",
                "value": "ANDROID_INTERNAL"
            },
            {
                "name": "Android External",
                "value": "ANDROID_EXTERNAL"
            },
            ],
            apps: {},
            currentPage: 1,
            totalPage: 0,
            itemsPerPage: 10,
            app_id: null,
            filterObj: {
                keyword: "",
                offset: 1,
                sort_by: "created_at",
                sort_order: "desc",
                row_per_page: 10,
                level: "",
                status: ""
            },
            appList: [],
            loader: false,
            disable:false,
            selected:true,
            has_disable:""
        };
    },
    mounted() {
        // const phone_number = document.querySelector("#phone_number");
        // this.iti_1 = intlTelInput(phone_number, {
        //     formatOnDisplay: true,
        //     utilsScript: utilJs,
        // });
        this.getList(1);
    },
    methods: {
        onClearForm(formRefName) {
            this.$refs[formRefName].resetForm();
        },
        openAddEditModel() {
            window.$("#addModal").modal("show");
        },
        editAppDetails(id) {
            this.$router.push("/edit-app/" + id)
        },
        closeAddEditModel() {
            this.onClearForm("addEditApp");
            window.$("#addModal").modal("hide");
            this.app_id = null;
        },
        updateStatus(e) {
            var status = e.target.checked;
            console.log(status);
            if (status == true) {
                this.apps.status = "ACTIVE";
            }
            if (status == false) {
                this.apps.status = "INACTIVE";
            }
            console.log(status, this.apps.status);
        },
        onSave() {

            if (this.apps.expired_at) {
                this.apps.expired_at = moment(this.apps.expired_at).format(
                    "yyyy-MM-DD"
                );
            }
            var method = "POST";
            var url = "app";
            if (this.app_id != null) {
                method = "PUT";
                url = "app/" + this.app_id;
            }
            this.$api
                .webRequest({
                    _method: method,
                    _action: url,
                    _body: this.apps,
                    _hide_loader: true,
                    _buttonId: "save-btn",
                }).then((res) => {
                    this.$toast.success(res.message, {
                        position: "top-right"
                    });
                    this.getList(1);
                    this.closeAddEditModel();
                })
                .catch((e) => {
                    this.$toast.error(e.message, {
                        position: "top-right"
                    });
                });
        },
        onUpdateDetails(uuid) {
            this.$api
                .webRequest({
                    _method: "GET",
                    _action: "app/" + uuid,
                }).then((res) => {
                    this.apps = res.info;
                    this.disable = true;    
                    this.app_id = res.info.id;
                    this.has_disable = res.info.type;

                    this.openAddEditModel();
                })
                .catch((e) => {
                    this.$toast.error(e.message, {
                        position: "top-right"
                    });
                });
        },
        pageChange(page) {
            this.getList(page);
        },
        itemsPerPageChange() {
            this.itemsPerPage = this.$refs.rolePagination.itemsPerPage;
            this.getList(1);
        },
        retry() {
            this.getList(1);
        },
        getList(page, sortBy, orderBy) {
            this.filterObj.offset = page - 1;
            this.currentPage = page;
            this.filterObj.row_per_page = this.itemsPerPage;

            if (sortBy) {
                this.filterObj.sort_by = sortBy;
            }
            if (orderBy) {
                this.filterObj.sort_order = orderBy;
            }

            this.loader = true;

            this.list = [];
            var method = "GET";
            this.$api
                .webRequest({
                    _method: method,
                    _action: "apps",
                    _body: this.filterObj,
                })
                .then((res) => {
                    this.loader = false;
                    this.appList = res.list;
                    // this.access = res.access;
                    if (page == 1) {
                        if (res.count != null && res.count > 0) {
                            if (this.$refs.rolePagination) {
                                this.$refs.rolePagination.setTotalCount(res.count);
                            }
                        } else {
                            if (this.$refs.rolePagination) {
                                this.$refs.rolePagination.setTotalCount(0);
                            }
                        }
                    }
                    if (!res.list || res.list.length <= 0) {
                        if (this.$refs.errorComponent) {
                            this.$refs.errorComponent.setTitle("Sorry, There is no record found.");
                        }
                    } else {
                        if (this.$refs.errorComponent) {
                            if (this.$refs.errorComponent.isShowMessage()) {
                                this.$refs.errorComponent.setTitle();
                            }
                        }
                    }
                    if (this.$refs.rolePagination) {
                        this.$refs.rolePagination.currentPage = this.currentPage;
                    }
                })
                .catch((e) => {
                    this.$toast.error(e.message, {
                        position: "top-right"
                    });
                    if (this.$refs.errorComponent) {
                        this.$refs.errorComponent.setTitle();
                    }
                });
        },
        sorting(e, sortBy) {
            var orderBy = "ASC";

            var className = "sorting_asc";
            var classes = e.target.className;
            if (classes.includes("sorting_asc")) {
                className = "sorting_desc";
            }

            $(".sorting_asc").removeClass("sorting_asc");
            $(".sorting_desc").removeClass("sorting_desc");

            if (className == "sorting_asc") {
                $(e.target).addClass("sorting_asc").removeClass("sorting_desc");
                orderBy = "ASC";
            } else if (className == "sorting_desc") {
                $(e.target).addClass("sorting_desc").removeClass("sorting_asc");
                orderBy = "DESC";
            }
            this.getList(1, sortBy, orderBy);
        },

        updateLitStatus(e, id) {
            var obj = {};
            var check = e.target.checked;
            console.log(check);
            if (check == true) {
                obj.status = "ACTIVE";
            }
            if (check == false) {
                obj.status = "INACTIVE";
            }
            this.$api
                .webRequest({
                    _method: "PUT",
                    _action: "app/" + id + "/status",
                    _body: obj,
                }).then((res) => {
                    this.$toast.success(res.message, {
                        position: "top-right"
                    });
                    this.getList(1);
                    this.closeAddEditModel();
                })
                .catch((e) => {
                    this.$toast.error(e.message, {
                        position: "top-right"
                    });
                });
        },



    },
};
</script>
