<template>
  <div class="mt-3">
    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
      <li class="nav-item" role="presentation">
        <button class="nav-link active" id="pills-home-tab" @click="changeTab('ACTIVE_USER')" data-bs-toggle="pill"
          data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Active
          User</button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link" id="pills-profile-tab" @click="changeTab('ARCHIVE_USER')" data-bs-toggle="pill"
          data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile"
          aria-selected="false">Archive User</button>
      </li>
    </ul>
  </div>
  <div class="page-card">

    <div class="tab-content" id="pills-tabContent">
      <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab"
        tabindex="0">
        <div class="row align-items-center mb-3">
          <div class="col-md-6 d-flex align-items-center gap-4">
            <h2 class="page-title">Users</h2>
            <a href="javascript:void(0)" v-on:click="openAddEditModel()" class="add-link ms-4">{{ $t("users.add_user")
            }}<i class="bi bi-plus-circle-fill"></i></a>
            <!-- <a href="javascript:void(0)" v-on:click="onCreateUser()" class="add-link ms-4" 
          >add user <i class="bi bi-plus-circle-fill"></i
          ></a> -->

          </div>

          <div class="col-md-6">
            <div class="d-flex justify-content-end font-12 text-uppercase gap-2 align-items-center">
              <span>show</span>
              <div class="bg-light-brand px-1">100</div>
              <span>entries</span>
            </div>
          </div>
        </div>
        <div class="row align-items-center pt-1 pb-2">
          <div class="col-lg-4">
            
            <div class="input-group flex-nowrap header-seach">
              <span class="input-group-text" id="addon-wrapping"><i class="bi bi-search"></i></span>
              <input type="text" 
                v-model="filterObj.keyword"
                v-on:keyup="getList(1)"
                class="form-control" placeholder="Search">
                <button type="button" id="show_btn" class="btn bg-transparent border-0 d-none" style="margin-left: -40px; z-index: 100;">
                  <span>X</span>
                </button>
            </div>
          </div>
        </div>
        <div class="table-wrap">
          <table class="table table-style">
            <thead>
              <tr>
                <th scope="col"><img src="images/sort-icon.svg" class="sort-cion"  @click="sorting($event,'id')"> {{ $t("users.id") }}</th>
                <th scope="col"><img src="images/sort-icon.svg" class="sort-cion" @click="sorting($event,'first_name')"> {{ $t("users.name") }} </th>
                <th scope="col" class="text-center"><img src="images/sort-icon.svg" class="sort-cion" @click="sorting($event,'email')"> {{
                  $t("users.email") }} </th>
                <th scope="col" class="text-center"><img src="images/sort-icon.svg" class="sort-cion"  @click="sorting($event,'phone_number')"> {{
                  $t("users.mobile_number") }} </th>
                <th scope="col" class="text-center"> {{ $t("users.app")
                }} </th>
                <th scope="col" class="text-center"><img src="images/sort-icon.svg" class="sort-cion" @click="sorting($event,'created_at')"> {{
                  $t("users.created") }} </th>
                <th scope="col" class="text-center"><img src="images/sort-icon.svg" class="sort-cion" @click="sorting($event,'expired_at')"> {{
                  $t("users.expired") }} </th>
                <th scope="col" class="text-center">{{ $t("users.action") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="loader">
                <td colspan="8" class="text-center">
                  <div class="spinner-border spinner-color" role="status">
                  </div>
                </td>
              </tr>
              <tr v-else v-for="item in userList" :key="item.id">
                <td>{{ item?.id }}</td>
                <td><a style="text-decoration:underline;cursor:pointer" v-on:click="editUserDetails(item.uuid)">{{
                  item?.full_name }}</a></td>
                <td class="text-center" v-if="item?.email">{{ item?.email }}</td>
                <td class="text-center" v-else>-</td>
                <td class="text-center" v-if="item?.phone_number">{{ item?.phone_number }}</td>
                <td class="text-center" v-else>-</td>
                <td class="text-center" v-if="item?.body_info?.apps?.type == 'ANDROID_PHONE'">Mobile</td>
                <td class="text-center" v-else-if="item?.body_info?.apps?.type == 'IOS_PHONE'">Mobile</td>
                <td class="text-center" v-else>-</td>
                <td class="text-center">{{ $helperService.getFormatedDateAndTime(item.created_at) }}</td>
                <td class="text-center" v-if="item.expired_at != null">{{
                  $helperService.getFormatedDateWithoutTime (item.expired_at) }}</td>
                <td class="text-center" v-else>-</td>
                <td>
                  <div class="action-ele">
                    <div class="form-check form-switch font-18">
                      <input class="form-check-input" title="Status" v-on:click="updateLitStatus($event, item.uuid)"
                        :value="item.status" :checked="item.status == 'ACTIVE' ? true : false" type="checkbox"
                        role="switch" id="flexSwitchCheckChecked">
                    </div>
                    <a href="javascript:void(0)" title="Edit" v-on:click="onUpdateDetails(item.uuid)"><img
                        src="images/icon-edit.svg" width="22" alt=""></a>
                    <a href="javascript:void(0)" title="Delete" v-on:click="openDeleteModel(item.uuid)"><img
                        src="images/icon-archive.svg" width="18" alt=""></a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <ErrorComponent @retry="getList(1)" ref="ACTIVE_USER_ERROR" />
        </div>
        <div class="row">
          <div class="col-lg-12 mt-5 text-center">
            <div class="page-bottom-pagination text-right">
              <Pagination @page-change="pageChange" @items-per-page-change="itemsPerPageChange"
                ref="ACTIVE_USER_PAGINATION" />
            </div>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabindex="0">
        <div class="row align-items-center pt-1 pb-2">
          <div class="col-lg-4">
            <div class="input-group flex-nowrap header-seach" >
              <span class="input-group-text" id="addon-wrapping"><i class="bi bi-search"></i></span>
              <input type="text"
              v-model="filterObj.keyword"
                
                v-on:blur="getList(1)"
                v-on:keyup.enter="$event.target.blur()"
              class="form-control" placeholder="Search">
            </div>
          </div>
        </div>
        <div class="table-wrap">
          <table class="table table-style">
            <thead>
              <tr>
                <th scope="col"><img src="images/sort-icon.svg" class="sort-cion"> {{ $t("users.id") }}</th>
                <th scope="col"><img src="images/sort-icon.svg" class="sort-cion"> {{ $t("users.name") }} </th>
                <th scope="col" class="text-center"><img src="images/sort-icon.svg" class="sort-cion"> {{
                  $t("users.email") }} </th>
                <th scope="col" class="text-center"><img src="images/sort-icon.svg" class="sort-cion"> {{
                  $t("users.mobile_number") }} </th>
                <th scope="col" class="text-center"><img src="images/sort-icon.svg" class="sort-cion"> {{ $t("users.app")
                }} </th>
                <th scope="col" class="text-center"><img src="images/sort-icon.svg" class="sort-cion"> {{
                  $t("users.created") }} </th>
                <th scope="col" class="text-center"><img src="images/sort-icon.svg" class="sort-cion"> {{
                  $t("users.expired") }} </th>
                <!-- <th scope="col" class="text-center">{{ $t("users.action") }}</th> -->
              </tr>
            </thead>
            <tbody>
              <tr v-if="loader">
                <td colspan="8" class="text-center">
                  <div class="spinner-border spinner-color" role="status">
                  </div>
                </td>
              </tr>
              <tr v-else v-for="item in userList" :key="item.id">
                <td>{{ item?.id }}</td>
                <td><a style="text-decoration:underline;cursor:pointer" v-on:click="editUserDetails(item.uuid)">{{
                  item?.full_name }}</a></td>
                <td class="text-center" v-if="item?.email">{{ item?.email }}</td>
                <td class="text-center" v-else>-</td>
                <td class="text-center" v-if="item?.phone_number">{{ item?.phone_number }}</td>
                <td class="text-center" v-else>-</td>
                <td class="text-center" v-if="item?.body_info?.apps?.type == 'ANDROID_PHONE'">Mobile</td>
                <td class="text-center" v-else-if="item?.body_info?.apps?.type == 'IOS_PHONE'">Mobile</td>
                <td class="text-center" v-else>-</td>
                <td class="text-center">{{ $helperService.getFormatedDateAndTime(item.created_at) }}</td>
                <td class="text-center" v-if="item.expired_at != null">{{
                  $helperService.getDateTimeWithoutUtc(item.expired_at) }}</td>
                <td class="text-center" v-else>-</td>
              </tr>
            </tbody>
          </table>
          <ErrorComponent @retry="getList(1)" ref="ARCHIVE_USER_ERROR" />
        </div>
        <div class="row">
          <div class="col-lg-12 mt-5 text-center">
            <div class="page-bottom-pagination text-right">
              <Pagination @page-change="pageChange" @items-per-page-change="itemsPerPageChange"
                ref="ARCHIVE_USER_PAGINATION" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- add Modal -->
  <div class="modal fade" id="addModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
      <div class="modal-content">

        <div class="modal-header">
          <h1 class="modal-title fs-5" v-if="user_id != null" id="exampleModalLabel">Edit user</h1>
          <h1 class="modal-title fs-5" v-else id="exampleModalLabel">Create user</h1>
          <button type="button" class="btn-close" v-on:click="closeAddEditModel()"></button>
        </div>
        <div class="modal-body form-style">
          <Form @submit="onSave()" ref="addEditUser">
            <div class="row">
              <div class="col-md-2" v-if="user_id">
                <p class=" text-secondary font-14">User ID <br>
                  <span class="font-medium">123</span>
                </p>
              </div>
              <div class="col-md-10">
                <div class="form-floating mb-2">
                  <Field type="text" class="form-control border-radius" name="first_name" v-model="users.first_name"
                    rules="required:first name" aria-autocomplete="none" :validateOnInput="true" id="first_name" placeholder="First Name" />
                  <label for="first_name">First Name</label>
                  <ErrorMessage name="first_name" class="validation-msg" />
                </div>
                <div class="form-floating mb-2">
                  <Field type="text" class="form-control border-radius" name="last_name" v-model="users.last_name"
                    rules="required:last name" aria-autocomplete="none"  :validateOnInput="true" id="last_name" placeholder="Last Name" />
                  <label for="last_name">Last name</label>
                </div>
                <ErrorMessage name="last_name" class="validation-msg" />
                <div class="input-group mb-2" v-if="user_id == null">
                  <span class="input-group-text border-radius" id="inputGroup-sizing-sm"><img src="/images/lock-icon.svg" width="18"
                      alt=""></span>
                  <div class="form-floating">
                    <Field  :type="passwordFieldType" autocomplete="password"  class="form-control border-radius" name="password" v-model="users.password"
                      rules="required:password" :validateOnInput="true" id="password" placeholder="password" />
                    <label for="password">Password</label>
                    <i id="button" alt="" class="show-icon fa fa-eye-slash field-icon" style=" top:15px !important"></i>
                  </div>
                </div>
                <ErrorMessage name="password" class="validation-msg" />
                <div class="input-group mb-2">
                  <div class="form-floating">
                    <Field v-slot="{ field }" name="level" rules="required:type,true" :validateOnInput="true"
                      v-model="users.type">
                      <select v-bind="field" class="form-select border-radius" id="floatingSelectGrid" v-model="users.type">
                        <option value="EMAIL">Email</option>
                        <option value="PHONE_NUMBER">Phone Number</option>
                      </select>
                      <label for="floatingSelectGrid">Type</label>
                    </Field>
                  </div>
                </div>

                <div class="input-group mb-2" v-if="users.type == 'EMAIL'">
                  <span class="input-group-text border-radius" id="inputGroup-sizing-sm"><img src="/images/email-icon.svg" width="18"
                      alt=""></span>
                  <div class="form-floating">
                    <Field type="email" aria-autocomplete="none"  class="form-control border-radius" name="email" v-model="users.email"
                      rules="required:email|email" :validateOnInput="true" id="email" placeholder="Email" />
                    <label for="email"> Email</label>
                  </div>
                  <ErrorMessage name="email" class="validation-msg" /><br>
                </div>
                <div class="input-group mb-2" v-if="users.type == 'PHONE_NUMBER'">
                  <span class="input-group-text border-radius" id="inputGroup-sizing-sm"><img src="/images/call-icon.svg" width="18"
                      alt=""></span>
                  <div class="form-floating">
                    <Field type="text" aria-autocomplete="none"  class="form-control border-radius" name="phone_number" v-model="users.phone_number"
                      rules="required:phone number" :validateOnInput="true" id="phone_number"
                      placeholder="Phone Number" />
                    <label for="phone_number">Phone Number</label>
                    <ErrorMessage name="phone_number" class="validation-msg" />
                  </div>
                </div>
                <label for="phone_number">Date Of Birth</label>
                <div class="input-group mb-2">
                  <span class="input-group-text border-radius" id="inputGroup-sizing-sm"><img src="/images/calendar-icon.svg" width="18"
                      alt=""></span>
                      <Field class="form-control border-radius" aria-autocomplete="none"  name="date_of_birth" id="date_of_birth"  rules="required:date of birth,true|ageValidation" v-model="users.date_of_birth" v-slot="{date}">
                        <VueDatePicker v-bind="date" name="date_of_birth" class="form-control border-radius" v-model="users.date_of_birth" hideInputIcon :clearable="false"
                        format="yyyy-MM-dd" placeholder="Select Date" :enable-time-picker="false"></VueDatePicker>
                      </Field>
                    </div>
                    <ErrorMessage name="date_of_birth" class="validation-msg" />
              </div>
            </div>
            <hr class=" border-secondary-subtle">
            <div class="row">
              <div class="col-md-4">
                <label for="" class="font-18 text-secondary mb-3">Gender at birth</label>
                <div class="form-check">
                  <input class="form-check-input" type="radio" v-model="users.gender" name="genderBtn" value="FEMALE"
                    id="Female" :checked="users.gender == 'FEMALE' ? true : false">
                  <label class="form-check-label" for="Female">
                    Female
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" v-model="users.gender" value="MALE" name="genderBtn"
                    id="Male" :checked="users.gender == 'MALE' ? true : false">
                  <label class="form-check-label" for="Male">
                    Male
                  </label>
                </div>
              </div>
              <div class="col-md-8">
                <div class="d-flex align-items-center gap-3 mb-3">
                  <label for="" class="font-18 text-secondary mb-0">Status</label>
                  <label class="font-14">Not active</label>
                  <div class="form-check form-switch">
                    <input class="form-check-input" v-on:click="updateStatus($event)" type="checkbox"
                      :checked="users.status == 'ACTIVE' ? true : false" role="switch" id="flexSwitchCheckDefault">
                    <label class="form-check-label" for="flexSwitchCheckDefault">Active</label>
                  </div>
                </div>
                <div v-if="user_id != null">
                  <label for="phone_number">Created At</label>
                  <div class="input-group flex-nowrap mb-3 input-group-lg">
                    <span class="input-group-text border-radius " id="addon-wrapping"><img src="/images/calendar-icon.svg" width="18"
                        alt=""></span>
                    <VueDatePicker :disabled="user_id" class="form-control border-radius" v-model="users.created_at" :enable-time-picker="false">
                    </VueDatePicker>
                  </div>
                  <label for="phone_number">Expired At</label>
                  <div class="input-group flex-nowrap mb-3 input-group-lg">
                    <span class="input-group-text border-radius bg-warning" id="addon-wrapping"><img src="/images/calendar-icon.svg"
                        width="18" alt=""></span>
                    <VueDatePicker class="form-control border-radius" v-model="users.expired_at" :enable-time-picker="false">
                    </VueDatePicker>
                  </div>
                </div>
              </div>
            </div>
            <hr class=" border-secondary-subtle">
            <div class="form-floating mb-2">
              <textarea v-model="users.notes" class="form-control border-radius" rows="5" cols="6" id="Note" placeholder="Note" />
              <label for="Note">Note</label>
            </div>
            <div class="modal-footer border-0 justify-content-start">
              <button id="save-user-btn" type="submit" class="btn btn-dark-brand px-4">Save</button>
              <button type="button" v-on:click="closeAddEditModel()" class="btn btn-light-brand px-4"
                data-bs-dismiss="modal">Close</button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">Confirmation </h1>
          <button type="button" class="btn-close" v-on:click="closeDeleteModel()"></button>
        </div>
        <div class="modal-body form-style">
          <div class="row pb-5">
            <div class="col-md-12">
              <h6>Are you sure you want to delete?</h6>
            </div>
          </div>

        </div>
        <div class="modal-footer border-0 justify-content-start">
          <button @click="closeDeleteModel()" type="button" class="btn btn-dark-brand px-4">Cancel</button>
          <button id="save-btn" type="button" v-on:click="onDelete()" class="btn btn-light-brand px-4"
            data-bs-dismiss="modal">Delete</button>
        </div>
      </div>
    </div>
  </div>
  <!--  -->
  <!--  -->
  <!--  -->
</template>
<style>
.spinner-color {
  color: "#351D4E"
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  background-color: #351d4e !important;
  color: #ffffff !important
}
.field-icon {
  float: right;
  margin-right: 8px;
  margin-top: -53px;
  position: relative;
  z-index: 2;
  cursor:pointer;
}
</style>
<script>
import Pagination from "@/components/Pagination";
import ErrorComponent from "@/components/ErrorComponent";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { Form, Field, ErrorMessage } from "vee-validate";
import moment from 'moment';
import $ from "jquery";
export default {
  name: "UsersList",
  components: {
    Form,
    Field,
    ErrorMessage,
    VueDatePicker,
    ErrorComponent,
    Pagination,
    // Multiselect
  },
  data() {
    return {
     
      types: [
        {
          "name": "Email",
          "value": "EMAIL"
        },
        {
          "name": "Phone number",
          "value": "PHONE_NUMBER"
        },],
      users: {
        type: "EMAIL"
      },
      currentPage: 1,
      totalPage: 0,
      itemsPerPage: 10,
      user_id: null,
      filterObj: {
        keyword: "",
        offset: 1,
        sort_by: "created_at",
        sort_order: "desc",
        row_per_page: 10,
        level: "",
        status: ""
      },
      userList: [],
      loader: false,
      active_tab: "ACTIVE_USER",
      passwordFieldType:"password"
    };
  },
  mounted() {
    $(document).ready(function () {
      $(".show-icon").on("click", function (event) {
        event.preventDefault();
        console.log("type : ", $(this).parent().find("input").attr("type"));
        var input = $(this).parent().find("input");
        if (input.attr("type") == "text") {
          input.attr("type", "password");
          $(this).parent().find("i").removeClass("fa-eye").addClass("fa-eye-slash");
        } else if (input.attr("type") == "password") {
          input.attr("type", "text");
          $(this).parent().find("i").removeClass("fa-eye-slash").addClass("fa-eye");
        }
      });
    });
    // const phone_number = document.querySelector("#phone_number");
    this.active_tab = localStorage.getItem("active_tab")
      ? localStorage.getItem("active_tab")
      : "ACTIVE_USER";
    this.changeTab(this.active_tab);
    this.getList(1);
  },
  methods: {
    show_cancle_btn(){
      window.$("#show_btn").addClass("d-block");
      window.$("#show_btn").removeClass("d-none");
    },
    changeTab(tab) {
      this.active_tab = tab;
      localStorage.setItem("active_tab", tab);
      if (tab == "ACTIVE_USER") {
        window.$("#pills-home-tab").click();
        this.getList(1, tab);
      } else if (tab == "ARCHIVE_USER") {
        window.$("#pills-profile-tab").click();
        this.getList(1, tab);
      } else {
        window.$("#pills-home-tab").click();
        this.getList(1);
      }
    },
    onClearForm(formRefName) {
      this.$refs[formRefName].resetForm();
    },
    editUserDetails(id) {
      this.$router.push("/edit-user/" + id)
    },
    openAddEditModel() {
      window.$("#addModal").modal("show");
    },
    closeAddEditModel() {
      this.onClearForm("addEditUser");
      this.users.date_of_birth = null;
      this.users.created_at = null;
      this.users.expired_at = null;
      this.user_id = null;
      this.users = {};
      window.$("#addModal").modal("hide");
    },
    openDeleteModel(uuid) {
      this.user_id = uuid;
      window.$("#deleteModal").modal("show");
    },
    closeDeleteModel() {
      this.user_id = null;
      window.$("#deleteModal").modal("hide");
    },
    updateStatus(e) {
      var status = e.target.checked;
      console.log(status);
      if (status == true) {
        this.users.status = "ACTIVE";
      }
      if (status == false) {
        this.users.status = "INACTIVE";
      }
      console.log(status, this.users.status);
    },
    onSave() {
      if (this.users.date_of_birth) {
        this.users.date_of_birth = moment(this.users.date_of_birth).format(
          "yyyy-MM-DD"
        );
      }


      if (this.users.expired_at) {
        this.users.expired_at = moment(this.users.expired_at).format(
          "yyyy-MM-DD hh:mm:ss"
        );
      }
      if (this.users.type === "EMAIL") {
        delete this.users.phone_number;
      }
      if (this.users.type === "PHONE_NUMBER") {
        delete this.users.email;
      }

      if(this.users.gender === ""){
        this.$toast.error("Please select gender", { position: "top-right" });
        return false;
      }
      console.log("data from the user:", this.users);
      var method = "POST";
      var url = "user";
      if (this.user_id != null) {
        method = "PUT";
        url = "user/" + this.user_id + "/update";
      }
      this.$api
        .webRequest({
          _method: method,
          _action: url,
          _body: this.users,
          _hide_loader: true,
          _buttonId: "save-user-btn",
        }).then((res) => {
          this.$toast.success(res.message, { position: "top-right" });
          this.getList(1);
          this.closeAddEditModel();
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
    },
    onUpdateDetails(uuid) {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "user/" + uuid,
        }).then((res) => {
          this.users = res.info;
          this.user_id = res.info.uuid;
          if (res.info.body_info != null) {
            this.users.gender = res.info.body_info.gender;
            if (res.info.body_info.date_of_birth) {
              this.users.date_of_birth = moment(res.info.body_info.date_of_birth).format(
                "yyyy-MM-DD"
              );
            }
          }

          this.openAddEditModel();
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
    },
    pageChange(page) {
      this.getList(page);
    },
    itemsPerPageChange() {
      var tab = this.active_tab;
      if (tab == "ACTIVE_USER") {
        this.itemsPerPage = this.$refs.ACTIVE_USER_PAGINATION.itemsPerPage;
      }
      if (tab == "ARCHIVE_USER") {
        this.itemsPerPage = this.$refs.ARCHIVE_USER_PAGINATION.itemsPerPage;
      }

      this.getDocumentList(1);
    },
    retry() {
      this.getList(1);
    },
    setPagination(page, count, listLength, paginationRef, errorCompRef) {
      if (page == 1) {
        if (count != null && count > 0) {
          if (this.$refs[paginationRef]) {
            this.$refs[paginationRef].setTotalCount(count);
          }
        } else {
          if (this.$refs[paginationRef]) {
            this.$refs[paginationRef].setTotalCount(0);
          }
        }
      }

      if (this.$refs[paginationRef]) {
        this.$refs[paginationRef].currentPage = this.currentPage;
      }

      if (listLength == 0) {
        if (this.$refs[errorCompRef]) {
          this.$refs[errorCompRef].setTitle("Sorry, There is no record found.");
        }
      }
    },
    getList(page, type, sortBy, orderBy) {
      window.$("#show_btn").addClass("d-none");
      window.$("#show_btn").removeClass("d-block");
      this.filterObj.type = type;
      this.filterObj.offset = page - 1;
      this.currentPage = page;
      this.filterObj.row_per_page = this.itemsPerPage;

      if (sortBy) {
        this.filterObj.sort_by = sortBy;
      }
      if (orderBy) {
        this.filterObj.sort_order = orderBy;
      }
      this.loader = true;
      this.userList = [];
      var method = "GET";
      this.$api
        .webRequest({
          _method: method,
          _action: "users",
          _body: this.filterObj,
        })
        .then((res) => {

          this.userList = res.list;
          this.loader = false;
          // this.access = res.access;
          var tab = this.active_tab;
          if (tab == "ACTIVE_USER") {
            this.setPagination(
              page,
              res.count,
              res.list.length,
              "ACTIVE_USER_PAGINATION",
              "ACTIVE_USER_ERROR"
            );
          } else if (tab == "ARCHIVE_USER") {
            this.setPagination(
              page,
              res.count,
              res.list.length,
              "ARCHIVE_USER_PAGINATION",
              "ARCHIVE_USER_ERROR"
            );
          }
        })
        .catch((e) => {
          this.loader = false;
          this.$toast.error(e.message, { position: "top-right" });
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setTitle();
          }
        });
    },
    sorting(e, sortBy) {
      var orderBy = "ASC";
      var className = "sorting_asc";
      var classes = e.target.className;
      if (classes.includes("sorting_asc")) {
        className = "sorting_desc";
      }
      $(".sorting_asc").removeClass("sorting_asc");
      $(".sorting_desc").removeClass("sorting_desc");

      if (className == "sorting_asc") {
        $(e.target).addClass("sorting_asc").removeClass("sorting_desc");
        orderBy = "ASC";
      } else if (className == "sorting_desc") {
        $(e.target).addClass("sorting_desc").removeClass("sorting_asc");
        orderBy = "DESC";
      }
      this.getList(1,this.type, sortBy, orderBy);
    },

    updateLitStatus(e, id) {
      var obj = {};
      var check = e.target.checked;
      console.log(check);
      if (check == true) {
        obj.status = "ACTIVE";
      }
      if (check == false) {
        obj.status = "INACTIVE";
      }
      this.$api
        .webRequest({
          _method: "PUT",
          _action: "user/" + id + "/status",
          _body: obj,
        }).then((res) => {
          this.$toast.success(res.message, { position: "top-right" });
          this.getList(1);
          this.closeAddEditModel();
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
    },

    onDelete() {
      this.$api
        .webRequest({
          _method: "DELETE",
          _action: "user/" + this.user_id,
          _buttonId: "save-btn"
        }).then((res) => {
          this.$toast.success(res.message, {
            position: "top-right"
          });
          this.getList(this.currentPage);
          this.question_id = null;
        })
        .catch((e) => {
          this.$toast.error(e.message, {
            position: "top-right"
          });
        });
    },

  },
};
</script>
