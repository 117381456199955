import { defineRule } from "vee-validate";
import moment from "moment";

defineRule("required", (value, [name, isSelect]) => {
    if (!value || value.length <= 0) {
        if (name) {
            if (!isSelect || isSelect == false) {
                return `Please enter ${name}`;
            } else {
                return `Please select ${name}`;
            }
        }
        return "This field is required";
    }
    return true;
});

defineRule("message", (value, [name]) => {
    if (value && value == "select") {
        return `Please select ${name}`;
    }
    return true;
});

defineRule("email", (value) => {
    if (
        value &&
        !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            value
        )
    ) {
        return "Please enter valid email";
    }
    return true;
});

defineRule("phone", (value) => {
    if (value && !/^[0-9]{10}$/.test(value)) {
        return "Please enter valid contact number";
    }
    return true;
});


defineRule("pincode", (value) => {
    if (value && !/^[0-9]{6}$/.test(value)) {
        return "Please enter valid pin code";
    }
    return true;
});

defineRule("userName", (value, [name]) => {
    if (value && !/^[A-Za-z][A-Za-z_]{1,}$/.test(value)) {
        if (name) {
            return `Please enter valid ${name}`;
        }
        return "Please enter valid name";
    }
    return true;
});

defineRule("minLength", (value, [limit]) => {
    if (value.length < limit) {
        return `This field must be at least ${limit} characters`;
    }
    return true;
});

defineRule("password", (value) => {
    if (value && !/^\S*$/.test(value)) {
        return "Space is not allowed in password";
    }
    return true;
});

defineRule("confirmed", (value, [target]) => {
    if (value === target) {
        return true;
    }
    return "Passwords must match";
});
defineRule("checkMax", (value, [target]) => {
    if (value > target) {
        return true;
    }
    return "Min value is not grater than max value";
});

defineRule("price", (value) => {
    if (value && !/^[1-9]\d*(\.\d{1,2}?)?$/.test(value)) {
        return "Please enter valid price";
    }
    return true;
});

defineRule("numeric", (value) => {
    if (value && !/^[0-9]\d*(\.\d{1,2}?)?$/.test(value)) {
        return "Please enter numeric values only";
    }
    return true;
});
defineRule("decimal", (value) => {
    if (value && !/^\d+\.\d{0,2}$/.test(value)) {
        return "Please enter decimal values only";
    }
    return true;
});

defineRule("ageValidation", (value) => {
    var newValue = moment(String(value)).format(
        "Y/MM/DD"
    );
    var optimizedBirthday = newValue.replace(/-/g, "/");

    //set date based on birthday at 01:00:00 hours GMT+0100 (CET)
    var myBirthday = new Date(optimizedBirthday);
    // set current day on 01:00:00 hours GMT+0100 (CET)
    var currentDate = new Date().toJSON().slice(0, 10) + ' 01:00:00';
    // calculate age comparing current date and borthday
    var myAge = ~~((Date.now(currentDate) - myBirthday) / (31557600000));
    console.log("the value of the age is : ", myAge);
    if (myAge < 18) {
        return "age must be 18+";
    } else {
        return true;
    }
});