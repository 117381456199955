import moment from "moment";
import WebService from "./WebService";
import Store from "./StoreService";

String.prototype.toProperCase = function () {
    return this.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
};

class HelperService {
    sorting(sortBy, id) {
        var orderBy = "ASC";
        var className = "sorting_asc";
        var temp = document.getElementById(id);
        if (temp) {
            if (temp.className.includes("sorting_asc")) {
                className = "sorting_desc";
            }
            if (className == "sorting_asc") {
                temp.classList.add("sorting_asc");
                temp.classList.remove("sorting_desc");
                orderBy = "ASC";
            } else if (className == "sorting_desc") {
                temp.classList.add("sorting_desc");
                temp.classList.remove("sorting_asc");
                orderBy = "DESC";
            }
        }
        var obj = {
            order_by: orderBy,
            sort_by: sortBy,
        };
        return obj;
    }

    getFormatedDate(d) {
        return moment(d).format("MMM DD, YYYY");
    }

    getFormatedTime(t) {
        return moment(t).format("hh:mm A");
    }

    getFormatedDateAndTime(dt) {
        return moment.utc(dt).local().format("DD.MM.YYYY | hh:mm A");
    }
    getDateTimeWithoutUtc(dt){
        return moment.parseZone(dt).format("DD.MM.YYYY | hh:mm A");
    }
    getFormatedDateWithoutTime(dt) {
        return moment.utc(dt).local().format("DD.MM.YYYY");
    }

    sendFormatedDate(d, type) {
        if (type == "date") {
            return moment(d).format("YYYY-MM-DD");
        } else if (type == "month_year") {
            return moment(d).format("YYYY-MM");
        } else if (type == "year") {
            return moment(d).format("YYYY");
        } else {
            return moment(d).format("YYYY-MM-DD");
        }
    }

    allowOnlyNumericValue(e) {
        var numbers = /^[0-9]$/;
        if (!e.key.match(numbers) && e.keyCode != 8) {
            e.preventDefault();
            return false;
        }
    }
    getTitleCaseFormate(value) {
        if (!value) {
            return "";
        }else{
            const arr = value.split("_");
            for (var i = 0; i < arr.length; i++) {
                arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
            }
            const str2 = arr.join(" ");
            return str2;
        }
    }
    getTitleCase(value) {

        if (!value) {
            return "";
        } 

        const pattern = /_/gi;
        const replacement = " ";
       const result = value.replaceAll(pattern, replacement);
       return result.toProperCase();
        // if (!value) {
        //     return "";
        // } else {
        //     var str = "";
        //     var arr = value.split("_");
        //     for (var i in arr) {
        //         if (i == 0) {
        //             str =
        //                 arr[i].charAt(0).toUpperCase() +  arr[i].substring(1).toLowerCase();
        //         } else {
        //             str +=
        //                 "" +
        //                 arr[i].charAt(0).toUpperCase() + 
        //                 arr[i].substring(1).toLowerCase();
        //         }
        //     }
        //     return str;
        // }
    }
    getSummary() {
        WebService.getAPI({
                _action: "summary",
            })
            .then((res) => {
                Store.commit("setSummary", res);
            })
            .catch(() => {});
    }

    dateTimeValue(value) {
        if (value && value != null && value != '') {
          var testDateUtc = moment.utc(value);
          var localDate = moment(testDateUtc).local();
      
          var dif = Math.floor((Date.now() - localDate) / 1000 / 86400);
      
          if (dif < 30) {
            var date = new Date(localDate),
              diff = (new Date().getTime() - date.getTime()) / 1000,
              daydiff = Math.floor(diff / 86400);
      
            if (isNaN(daydiff) || daydiff < 0 || daydiff >= 31) return "";
      
            return (
              (daydiff == 0 &&
                ((diff < 60 && "Just now") ||
                  (diff < 120 && "1 minute ago") ||
                  (diff < 3600 && Math.floor(diff / 60) + " minutes ago") ||
                  (diff < 7200 && "1 hour ago") ||
                  (diff < 86400 && Math.floor(diff / 3600) + " hours ago"))) ||
              (daydiff == 1 && "Yesterday") ||
              (daydiff < 7 && daydiff + " days ago") ||
              (daydiff < 31 && Math.ceil(daydiff / 7) + " weeks ago")
            );
          } else {
            return moment.utc(value).local().format("DD.MM.YYYY | hh:mm A");
          }
      
        }
      }
}

export default new HelperService({});