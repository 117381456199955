<template>
  <div>
      <div class="container min-vh-100 d-flex justify-content-center align-items-center">
          <div class="login-wrap">
              <div class="row">
                  <div class="col-lg-6 bg-brand d-flex align-items-center justify-content-center">
                      <div class="text-center">
                          <img src="images/MVS-logo-white.svg" width="220" alt="Logo" class="mb-2">
                          <p class=" text-uppercase font-14 text-white" style="letter-spacing: 5px;">admin</p>
                      </div>
                  </div>
                  <div class="col-lg-6 bg-white py-5">
                      <div class="px-5">
                          <h4 class="font-medium text-brand mb-0 text-center">Welcome to MyVitalScan admin! </h4>
                          <p class="text-brand text-center mb-4">Reset Password</p>
                          <Form @submit="onResetPassword" ref="loginForm">
                              <div class="form-floating mb-3">
                                  <Field type="password" class="form-control" name="password" v-model="password" rules="required:password "
                                      :validateOnInput="true" id="password" placeholder="name@example.com" />
                                  <label for="password">password <span class="text-danger">*</span></label>
                                  <ErrorMessage name="password" class="validation-msg" />
                              </div>
                              <div class="form-floating mb-3">
                                  <Field type="password" name="conf_password"  rules="required:confirm password|confirmed:@password" id="conf_password"
                                      :validateOnInput="true" v-model="confirm_password" class="form-control"
                                      placeholder="Confirm Password" />
                                  <label for="conf_password">Confirm Password <span class="text-danger">*</span></label>
                                  <ErrorMessage name="conf_password" class="validation-msg" />
                              </div>
                              <button type="submit" id="save-btn" class="btn btn-login mb-3">Reset Password</button>
                          </Form>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
  name: "PortalLogin",
  components: {
      Form,
      Field,
      ErrorMessage,
  },
  data() {
      return {
        token:this.$route.params.token,
          password: "",
          confirm_password: "",
          passwordFieldType: "password",
          image: "/images/password.svg",
      };
  },
  mounted() {

  },
  methods: {
      onClearForm(formRefName) {
          this.$refs[formRefName].resetForm();
      },
      onResetPassword() {
          this.$api
              .webRequest({
                  _method:"POST",
                  _action: "reset/password/" + this.token,
                  _buttonId: "save-btn",
                  _body: { password: this.password},
                  _hide_loader: true,
              })
              .then((res) => {
                  if (res ) {
                      this.$toast.success(res.message, {
                          position: 'top-right'
                      });
                     this.$router.push("/login");
                  }

              })
              .catch((e) => {
                  this.$toast.error(e.message, {
                      position: 'top-right'
                  });
              });
      },
      onSwitchVisibility() {
          this.passwordFieldType =
              this.passwordFieldType === "password" ? "text" : "password";
          this.image =
              this.image === "/images/password.svg" ? "/images/password.svg" : "/images/password.svg";
      },
      
  },
};
</script>

