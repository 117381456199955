<template>
  <div class="page-card">
    <div class=" border-bottom mb-3">
      <h2 class="page-title mb-2">Settings</h2>
    </div>
    <h3 class="font-18 text-secondary fw-normal mb-3">Languages</h3>
    <div class="row form-style">
      <div class="col-lg-6  mb-3">
        <div class="row row-cols-2 mb-3">
          <div class="col">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="english" checked>
              <label class="form-check-label" for="english">
                English
              </label>
            </div>
          </div>
          <div class="col">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="lang-2">
              <label class="form-check-label" for="lang-2">
                Española
              </label>
            </div>
          </div>
          <div class="col">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="lang-3">
              <label class="form-check-label" for="lang-3">
                Mi'kmaq
              </label>
            </div>
          </div>
          <div class="col">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="lang-4">
              <label class="form-check-label" for="lang-4">
                Français
              </label>
            </div>
          </div>
          <div class="col">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="lang-5">
              <label class="form-check-label" for="lang-5">
                普通话
              </label>
            </div>
          </div>
          <div class="col">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="lang-6">
              <label class="form-check-label" for="lang-6">
                Gaeilge
              </label>
            </div>
          </div>
        </div>

        <div class="border-bottom mb-3"></div>
        <Form @submit="onSave()" ref="addEditUser">
          <h3 class="font-18 text-secondary fw-normal mb-3">Admin </h3>
          <div class="form-floating mb-2">
            <Field type="text" class="form-control" name="first_name" v-model="info.first_name"
              rules="required:first name" :validateOnInput="true" id="first_name" placeholder="First Name" />
            <label for="first_name">First Name</label>
            <ErrorMessage name="first_name" class="validation-msg" />
          </div>
          <div class="form-floating mb-2">
            <Field type="text" class="form-control" name="last_name" v-model="info.last_name" rules="required:lats name"
              :validateOnInput="true" id="last_name" placeholder="Last Name" />
            <label for="last_name">Last name</label>
          </div>
          <ErrorMessage name="last_name" class="validation-msg" />
          <div class="input-group mb-2">
            <span class="input-group-text" id="inputGroup-sizing-sm"><img src="images/email-icon.svg" width="18"
                alt=""></span>
            <div class="form-floating">
              <Field type="email" class="form-control" name="email" v-model="info.email" rules="required:email|email"
                :validateOnInput="true" id="email" placeholder="Email" />
              <label for="email"> Email</label>
              <ErrorMessage name="email" class="validation-msg" />
            </div>
          </div>
          <div class="d-flex justify-content-between  pt-3">
            <button id="save-btn" type="submit" class="btn btn-dark-brand px-4">Save</button>
            <h6 style="cursor:pointer;text-decoration: underline;" v-on:click="onChangePasswordModel()">change password</h6>
          </div>
        </Form>
      </div>
    </div>
    <div class="modal fade" id="changePassword" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
        <div class="modal-content">

          <div class="modal-header">
            
            <h1 class="modal-title fs-5" id="exampleModalLabel">Change Password</h1>
            <button type="button" class="btn-close" v-on:click="onCloseChangePassword()"></button>
          </div>
          <div class="modal-body form-style">
            <Form @submit="onChangePassword()" ref="changePassword">
              <div class="row">
                <div class="col-md-12">
                  <div class="input-group mb-2" v-if="user_id == null">
                  <span class="input-group-text" id="inputGroup-sizing-sm"><img src="images/lock-icon.svg" width="18"
                      alt=""></span>
                  <div class="form-floating">
                    <Field :type="currentPasswordFieldType"  class="form-control" name="current_password" v-model="changePassword.current_password"
                      rules="required|password" :validateOnInput="true" id="current_password" placeholder="current password" />
                    <label for="current_password">Current Password</label>
                  </div>
                </div>
                <ErrorMessage name="current_password" class="validation-msg" />
                </div>

                <div class="col-md-12 pb-3">
                  <div class="input-group mb-2" v-if="user_id == null">
                  <span class="input-group-text" id="inputGroup-sizing-sm"><img src="images/lock-icon.svg" width="18"
                      alt=""></span>
                  <div class="form-floating">
                    <Field :type="newpasswordFieldType" class="form-control" name="password" v-model="changePassword.password"
                      rules="required|password" :validateOnInput="true" id="password" placeholder="password" />
                    <label for="password">Password</label>
                  </div>
                </div>
                <ErrorMessage name="password" class="validation-msg" />
                </div>
                
              </div>
              <div class="modal-footer border-0 justify-content-start">
                <button id="save-btn" type="submit" class="btn btn-dark-brand px-4">Save</button>
                <button type="button" v-on:click="onCloseChangePassword()" class="btn btn-light-brand px-4"
                  data-bs-dismiss="modal">Close</button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
  name: "PageSetting",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      info: {},
      changePassword:{
        current_password:"",
        password:"",
      },
      currentPasswordFieldType: "password",
      newpasswordFieldType: "password",
    };
  },
  mounted() {
    this.onGetMe();
  },
  methods: {
    onClearForm(formRefName) {
        this.$refs[formRefName].resetForm();
    },

    onChangePasswordModel() {
        this.onClearForm("changePassword");
        window.$("#changePassword").modal("show");
    },
    onCloseChangePassword() {
        this.onClearForm("changePassword");
        window.$("#changePassword").modal("hide");
    },
    onGetMe() {
      var method = "GET";
      this.$api
        .webRequest({
          _method: method,
          _action: "me",
          _hide_loader: true,
        }).then((res) => {
          this.info = res.info;
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
    },
    onChangePassword(){
      var method = "POST";
      this.$api
        .webRequest({
          _method: method,
          _action: "change-password",
          _body:this.changePassword,
          _buttonId:"save-btn",
          _hide_loader: true,
        }).then((res) => {
          this.$toast.success(res.message,{ position: "top-right"});
          this.onCloseChangePassword();
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right"});
        });
    },
    onSave(){
      var method = "POST";
      this.$api
        .webRequest({
          _method: method,
          _action: "update-profile",
          _body:this.info,
          _buttonId:"save-btn",
          _hide_loader: true,
        }).then((res) => {
          this.$toast.success(res.message,{ position: "top-right"});
          this.onGetMe();
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right"});
        });
    },
  },
};
</script>
