<template>
  <div class="page-header">
  <div class="row align-items-center">
    <div class="col-md-4">
        <!-- <div class="input-group flex-nowrap header-seach">
            <span class="input-group-text" id="addon-wrapping"><i class="bi bi-search"></i></span>
            <input type="text" class="form-control" placeholder="Search">
        </div> -->
    </div>
    <!-- <div class="col-md-8 text-end d-flex gap-3 justify-content-end">
      <a href="" class="header-nav"> 
        <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M10.7767 11.4655C10.1077 11.4655 9.44073 11.2445 8.88273 10.8025L4.39773 7.18648C4.07473 6.92648 4.02473 6.45348 4.28373 6.13148C4.54473 5.81048 5.01673 5.75948 5.33873 6.01848L9.81973 9.63048C10.3827 10.0765 11.1757 10.0765 11.7427 9.62648L16.1787 6.02048C16.5007 5.75748 16.9727 5.80748 17.2347 6.12948C17.4957 6.45048 17.4467 6.92248 17.1257 7.18448L12.6817 10.7965C12.1197 11.2425 11.4477 11.4655 10.7767 11.4655" fill="#494BA2"/>
          <mask id="mask0_1_520" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="20">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H21.4999V19.5H0V0Z" fill="white"/>
          </mask>
          <g mask="url(#mask0_1_520)">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M5.839 18H15.659C15.661 17.998 15.669 18 15.675 18C16.816 18 17.828 17.592 18.604 16.817C19.505 15.92 20 14.631 20 13.188V6.32C20 3.527 18.174 1.5 15.659 1.5H5.841C3.326 1.5 1.5 3.527 1.5 6.32V13.188C1.5 14.631 1.996 15.92 2.896 16.817C3.672 17.592 4.685 18 5.825 18H5.839ZM5.822 19.5C4.279 19.5 2.901 18.94 1.837 17.88C0.652 16.698 0 15.032 0 13.188V6.32C0 2.717 2.511 0 5.841 0H15.659C18.989 0 21.5 2.717 21.5 6.32V13.188C21.5 15.032 20.848 16.698 19.663 17.88C18.6 18.939 17.221 19.5 15.675 19.5H15.659H5.841H5.822Z" fill="#494BA2"/>
          </g>
          </svg>
      </a>
      <a href="" class="header-nav"> 
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_1_529" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="3" y="1" width="19" height="18">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3 1H21.4969V18.348H3V1Z" fill="white"/>
        </mask>
        <g mask="url(#mask0_1_529)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.247 2.5C8.75201 2.5 6.31601 5.238 6.31601 7.695C6.31601 9.774 5.73901 10.735 5.22901 11.583C4.82001 12.264 4.49701 12.802 4.49701 13.971C4.66401 15.857 5.90901 16.848 12.247 16.848C18.55 16.848 19.834 15.813 20 13.906C19.997 12.802 19.674 12.264 19.265 11.583C18.755 10.735 18.178 9.774 18.178 7.695C18.178 5.238 15.742 2.5 12.247 2.5M12.247 18.348C7.57101 18.348 3.34501 18.018 3.00001 14.035C2.99701 12.387 3.50001 11.549 3.94401 10.811C4.39301 10.063 4.81601 9.358 4.81601 7.695C4.81601 4.462 7.80201 1 12.247 1C16.692 1 19.678 4.462 19.678 7.695C19.678 9.358 20.101 10.063 20.55 10.811C20.994 11.549 21.497 12.387 21.497 13.971C21.148 18.018 16.923 18.348 12.247 18.348" fill="#494BA2"/>
        </g>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.198 22.5025H12.196C11.075 22.5015 10.014 22.0075 9.20903 21.1105C8.93203 20.8035 8.95703 20.3285 9.26503 20.0525C9.57303 19.7745 10.047 19.7995 10.324 20.1085C10.842 20.6855 11.507 21.0025 12.197 21.0025H12.198C12.891 21.0025 13.559 20.6855 14.078 20.1075C14.356 19.8005 14.83 19.7755 15.137 20.0525C15.445 20.3295 15.47 20.8045 15.193 21.1115C14.385 22.0085 13.322 22.5025 12.198 22.5025" fill="#494BA2"/>
        </svg>

      </a>
    </div> -->
  </div>

   </div>
</template>
<script>
export default {
  name: "HeaderTop",
  components: {
  },
  data() {
    return {
     
    };
  },
 
  methods: {
    
  },
  mounted() {
   
  },
};
</script>
